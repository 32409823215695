import { useSelector, useDispatch } from 'react-redux';
import {
    selectBillingModalOpen,
    toggleBillingModalOpen,
    toggleSubscribeModal
} from '../../../../redux/reducers/generic/billing';
import Modal from '../../../Components/Modal';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { getUserStripeLink } from '../../../../redux/actions/solo/getUserStripeLink';
import { upgradeToSoloPremium } from '../../../../redux/actions/solo/upgradeToSoloPremium';
import { soloPremiumPlan } from '../../../Pricing/prices';

const BillingDetail = ({ title, value, className = '' }) => (
    <div className="sm:flex">
        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            {title}
        </dt>
        <dd className="flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className={className}>{value}</div>
        </dd>
    </div>
);

export const BillingModal = () => {
    const billingModalOpen = useSelector(selectBillingModalOpen);
    const dispatch = useDispatch();
    const { accessTokenPayload } = useSessionContext();
    const { subscription } = useSubscriptionPayment();
    const handleModalClose = () => dispatch(toggleBillingModalOpen());
    const handleUpgradeClick = () => {
        dispatch(toggleBillingModalOpen());
        dispatch(toggleSubscribeModal(true));
    };
    const formatDate = date => moment(date).format('MMM Do YYYY');

    const [stripeLink, setStripeLink] = useState('');
    const [isUpgrading, setIsUpgrading] = useState(false);

    useEffect(() => {
        const getLink = async () => {
            let link = await dispatch(getUserStripeLink());
            setStripeLink(link);
        };
        getLink();
    }, []);

    const handleUpgradeToPremium = async () => {
        const price =
            subscription.duration === 'year'
                ? soloPremiumPlan.yearlyPrice / 12
                : soloPremiumPlan.monthlyPrice;
        const priceString = `$${price / 100}/${
            subscription.duration === 'year'
                ? 'month (billed annually)'
                : 'month'
        }`;
        if (
            window.confirm(
                `Are you sure you want to upgrade to Solo Premium? You will be charged ${priceString}.`
            )
        ) {
            setIsUpgrading(true);
            try {
                const success = await dispatch(upgradeToSoloPremium());
                if (success) {
                    window.location.reload();
                } else {
                    alert('Upgrade failed. Please try again.');
                }
            } catch (error) {
                console.error('Error upgrading to Solo Premium:', error);
                alert('An error occurred while upgrading. Please try again.');
            } finally {
                setIsUpgrading(false);
            }
        }
    };

    return (
        subscription &&
        !subscription.loading && (
            <Modal open={billingModalOpen} onClose={handleModalClose}>
                <>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Billing
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                        Manage your subscription with pre.dev here.
                    </p>

                    <div className="mt-3 space-y-4 divide-y divide-gray-100 text-sm leading-6">
                        <BillingDetail
                            title="Email address"
                            value={accessTokenPayload.email}
                        />
                        <BillingDetail
                            title="Plan"
                            value={subscription.tier
                                .split(/(?=[A-Z])/)
                                .join(' ')}
                            className="text-green-800"
                        />
                        <BillingDetail
                            title="Subscription Status"
                            value={
                                subscription.status === 'active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                            className={
                                subscription.status === 'active'
                                    ? 'text-green-800'
                                    : 'text-red-800'
                            }
                        />

                        {subscription.status !== 'inactive' && (
                            <>
                                <BillingDetail
                                    title="Price"
                                    value={`$${subscription.price} / ${subscription.duration}`}
                                />
                                <BillingDetail
                                    title="Subscription Start"
                                    value={formatDate(subscription.created)}
                                />
                                <BillingDetail
                                    title={
                                        subscription.cancel_at_period_end
                                            ? 'Subscription End'
                                            : 'Next Billing Date'
                                    }
                                    value={formatDate(
                                        subscription.current_period_end
                                    )}
                                />
                            </>
                        )}
                    </div>

                    <div className="mt-6 flex flex-col items-center space-y-4">
                        {subscription.status !== 'inactive' ? (
                            <>
                                {subscription.tier === 'SoloPlus' && (
                                    <button
                                        className="w-full px-4 py-2 text-sm font-medium text-white bg-purple-500 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                                        onClick={handleUpgradeToPremium}
                                        disabled={isUpgrading}
                                    >
                                        {isUpgrading ? (
                                            <span className="flex items-center justify-center">
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                Upgrading...
                                            </span>
                                        ) : (
                                            `Upgrade to Solo Premium ($${
                                                subscription.duration === 'year'
                                                    ? soloPremiumPlan.yearlyPrice /
                                                      12 /
                                                      100
                                                    : soloPremiumPlan.monthlyPrice /
                                                      100
                                            }/${
                                                subscription.duration === 'year'
                                                    ? 'month, billed annually'
                                                    : 'month'
                                            })`
                                        )}
                                    </button>
                                )}
                                <a
                                    className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-center"
                                    href={stripeLink}
                                    onClick={handleModalClose}
                                >
                                    Update /{' '}
                                    {subscription.cancel_at_period_end
                                        ? 'Renew'
                                        : 'Cancel'}{' '}
                                    Subscription
                                </a>
                            </>
                        ) : (
                            <button
                                className="w-full px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={handleUpgradeClick}
                            >
                                Upgrade Plan
                            </button>
                        )}
                    </div>
                </>
            </Modal>
        )
    );
};
