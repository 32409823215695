import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT } from '../../../api/graphQL';
import { useNavigate } from 'react-router-dom';
import { addToProjects } from '../../../redux/reducers/generic/projects';
import { ContinueButton } from '../../CreateNewProject';
import UploadRFP from '../../CreateNewProject/UploadRFP';
import NewExistingProjectPicker from '../../CreateNewProject/NewExistingProjectPicker';
import { BudgetSlider } from '../../CreateNewProject/BudgetSlider';

export function NewProjectInput() {
    const dispatch = useDispatch();
    const [newProjectName, setNewProjectName] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [createProject] = useMutation(CREATE_PROJECT);
    const navigate = useNavigate();
    const [fileKey, setFileKey] = useState(null);
    const [option, setOption] = useState('new');
    const [isUploading, setUploading] = useState(false);
    const [matchWithDev, setMatchWithDev] = useState(false);
    const [budget, setBudget] = useState(10000);

    async function handleNewProjectSubmit(e) {
        e.preventDefault();
        if (isUploading) return;
        if (newProjectName.trim() !== '') {
            setLoading(true);
            try {
                const newProject = await createProject({
                    variables: {
                        name: newProjectName,
                        type: 'other',
                        hasExistingContext: option === 'existing',
                        fileKeyS3: fileKey,
                        matchWithDev,
                        budget: budget.toString()
                    }
                });
                navigate(`/projects/${newProject.data.createProject._id}`);
                dispatch(addToProjects(newProject.data.createProject));
            } catch (error) {
                console.error({ error });
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            try {
                document.getElementById('createNewProject1')?.focus();
            } catch (err) {
                console.error(err);
            }
        }, 100);
    }, []);

    return (
        <div>
            <form
                onSubmit={handleNewProjectSubmit}
                className="mb-4 flex flex-col justify-center"
            >
                <NewExistingProjectPicker
                    option={option}
                    setOption={setOption}
                />
                <input
                    id="createNewProject1"
                    type="text"
                    placeholder="Project Name"
                    value={newProjectName}
                    onChange={e => setNewProjectName(e.target.value)}
                    className="border-gray-400 px-4 py-2 w-full outline-none mb-4"
                />

                <UploadRFP
                    setFileKey={setFileKey}
                    isUploading={isUploading}
                    setUploading={setUploading}
                />

                <div className="flex items-center mt-4 mb-2">
                    <input
                        type="checkbox"
                        id="matchWithDev"
                        checked={matchWithDev}
                        onChange={e => setMatchWithDev(e.target.checked)}
                        className="mr-2"
                    />
                    <label
                        htmlFor="matchWithDev"
                        className="text-sm text-gray-700"
                    >
                        Match me with a dev
                    </label>
                </div>
                <p className="text-xs text-gray-500">
                    Allow developer agencies to contact me about my project
                </p>

                <div className="mt-4 max-w-sm w-full">
                    <BudgetSlider
                        textColor="text-gray-700"
                        budget={budget}
                        setBudget={setBudget}
                    />
                </div>

                <div className="h-4" />
                <ContinueButton isLoading={isLoading} />
            </form>
        </div>
    );
}
