import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'sonner';
import {
    ClipboardIcon,
    InformationCircleIcon
} from '@heroicons/react/24/outline';
import * as Tooltip from '@radix-ui/react-tooltip';
import { SendIntroductionEmail } from './SendIntroductionEmail';
import { RevealEmail } from './RevealEmail';
import { getCountryFlagEmoji } from '../../ClientProjects/ProjectsList/item';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ProjectName = ({ name, bgColor, execSummary, country }) => (
    <div className="flex items-center space-x-3 lg:pl-2">
        {country ? (
            <span className="text-sm text-gray-500">
                {getCountryFlagEmoji(country)}
            </span>
        ) : (
            <div
                className={classNames(
                    bgColor,
                    'h-2.5 w-2.5 flex-shrink-0 rounded-full'
                )}
                aria-hidden="true"
            />
        )}
        <a className="hover:text-gray-600 flex items-center">
            <span>{name}</span>
            <Tooltip.Provider delayDuration={100}>
                <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                        <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-400 cursor-help" />
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="max-w-md p-4 bg-white shadow-lg rounded-lg border border-gray-200"
                            sideOffset={5}
                        >
                            <p className="text-sm text-gray-700">
                                {execSummary}
                            </p>
                            <Tooltip.Arrow className="fill-white" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        </a>
    </div>
);

const UserEmail = ({
    email,
    projectId,
    showShareLink,
    leadGenMeetingTriggered,
    isRevealed
}) => {
    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText(email).then(() => {
            toast('User email copied to clipboard!', {
                type: 'success'
            });
        });
    };

    return (
        <div className="flex items-center space-x-2">
            {email ? (
                <div className="flex items-center w-full justify-between gap-x-2">
                    <div
                        className="flex gap-x-2 cursor-pointer"
                        onClick={copyEmailToClipboard}
                    >
                        <span>{email}</span>
                        <ClipboardIcon
                            className={`h-5 transition-opacity duration-300 ${
                                !showShareLink ? 'opacity-0' : ''
                            }`}
                        />
                    </div>
                    {leadGenMeetingTriggered ? (
                        <span className="px-2 py-1 text-xs font-medium text-green-700 bg-green-100 rounded-full">
                            Introduction Sent
                        </span>
                    ) : (
                        <SendIntroductionEmail projectId={projectId} />
                    )}
                </div>
            ) : (
                <div className="relative">
                    {showShareLink && !isRevealed ? (
                        <RevealEmail projectId={projectId} />
                    ) : (
                        <div className="px-3 py-1.5 text-sm font-medium text-gray-600 bg-gray-200 rounded-md animate-pulse">
                            ••••••@•••••.com
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const formatDate = isoDate => {
    const date = new Date(isoDate);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit'
        });
    } else {
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        });
    }
};

export const TableItem = ({
    project,
    bgColor,
    setCurrentProject,
    currentProject
}) => {
    const navigate = useNavigate();

    const onClickProject = () => {
        navigate(project._id);
    };

    const showShareLink = currentProject?._id === project._id;
    return (
        <tr
            className="cursor-pointer hover:bg-gray-50"
            key={project._id}
            onMouseEnter={() => setCurrentProject(project)}
            onMouseLeave={() => setCurrentProject(null)}
        >
            <td
                className="px-2 py-3 text-sm font-medium text-gray-900 max-w-[400px]"
                onClick={onClickProject}
            >
                <ProjectName
                    name={project.name}
                    bgColor={bgColor}
                    execSummary={project.execSummary}
                    country={project.country}
                />
            </td>
            <td className="px-2 py-3 text-sm font-medium text-gray-500">
                <UserEmail
                    email={project.userEmail}
                    projectId={project._id}
                    showShareLink={showShareLink}
                    leadGenMeetingTriggered={project.leadGenMeetingTriggered}
                    isRevealed={project.leadGenEmailRevealed}
                />
            </td>

            <td
                onClick={onClickProject}
                className="hidden whitespace-nowrap px-2 py-3 text-sm text-gray-500 md:table-cell"
            >
                {project?.budget && (
                    <>${parseInt(project?.budget)?.toLocaleString()}</>
                )}
            </td>
            <td
                onClick={onClickProject}
                className="hidden whitespace-nowrap px-2 py-3 text-center text-xs text-gray-500 md:table-cell"
            >
                {formatDate(project.updated)}
            </td>
            {/* <td
                onClick={onClickProject}
                className="hidden whitespace-nowrap px-2 py-3 text-center text-sm text-gray-500 md:table-cell"
            >
                {Math.floor(project.messageCount / 2)}
            </td> */}
        </tr>
    );
};
