import { INITIATE_HERO_RANDOM_EXPERIENCE_STREAM } from '../../../api/graphQL/enterprise_widget/mutator';
import { client } from '../../../api/client';

export const initiateHeroRandomExperience =
    ({ randId, answers, inspiration }) =>
    async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: INITIATE_HERO_RANDOM_EXPERIENCE_STREAM,
                variables: { randId, answers, inspiration }
            });
            return data.initiateHeroRandomExperienceStream;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
