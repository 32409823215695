import gql from 'graphql-tag';
import {
    defaultTechStackItem,
    enterprise,
    enterprise_subscription_link,
    enterpriseUsers,
    knowledgeBaseFile,
    project
} from '../helper';

export const CREATE_ENTERPRISE_ORGANIZATION = gql`
	mutation createEnterpriseOrganization($name: String!) {
		createEnterpriseOrganization(name: $name) {
			${enterprise}
		}
	}
`;

export const UPDATE_ENTERPRISE_ORGANIZATION_BOOLEAN_FIELD = gql`
    mutation updateEnterpriseOrganizationBooleanField(
        $enterpriseOrganizationId: ID!
        $field: String!
        $value: Boolean
    ) {
        updateEnterpriseOrganizationBooleanField(
            enterpriseOrganizationId: $enterpriseOrganizationId
            field: $field
            value: $value
        ) {
            ${enterprise}
        }
    }
`;

export const UPDATE_ENTERPRISE_ORGANIZATION_STRING_FIELD = gql`
    mutation updateEnterpriseOrganizationStringField(
        $enterpriseOrganizationId: ID!
        $field: String!
        $value: String
    ) {
        updateEnterpriseOrganizationStringField(
            enterpriseOrganizationId: $enterpriseOrganizationId
            field: $field
            value: $value
        ) {
            ${enterprise}
        }
    }
`;

export const ADD_USER_TO_ENTERPRISE_ORGANIZATION = gql`
    mutation addUserToEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
    ) {
        addUserToEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            ${enterpriseUsers}
            numUsersLeft
        }
    }
`;

export const MAKE_USER_ADMIN_OF_ENTERPRISE_ORGANIZATION = gql`
    mutation makeUserAdminOfEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
    ) {
        makeUserAdminOfEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            ${enterpriseUsers}
        }
    }
`;

export const UPDATE_USER_TITLE_OF_ENTERPRISE_ORGANIZATION = gql`
    mutation updateUserTitleOfEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
        $title: String!
    ) {
        updateUserTitleOfEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
            title: $title
        ) {
            ${enterpriseUsers}
        }
    }
`;

export const REMOVE_USER_FROM_ENTERPRISE_ORGANIZATION = gql`
    mutation removeUserFromEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
    ) {
        removeUserFromEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            ${enterpriseUsers}
            numUsersLeft
        }
    }
`;

export const ADD_DOMAIN_TO_ENTERPRISE_ORGANIZATION = gql`
    mutation addDomainToEnterpriseOrganization(
        $url: String!
        $enterpriseOrganizationId: ID!
    ) {
        addDomainToEnterpriseOrganization(
            url: $url
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            apiDomains {
                _id
                url
                index
                created
            }
        }
    }
`;

export const REMOVE_DOMAIN_FROM_ENTERPRISE_ORGANIZATION = gql`
    mutation removeDomainFromEnterpriseOrganization(
        $domainId: ID!
        $enterpriseOrganizationId: ID!
    ) {
        removeDomainFromEnterpriseOrganization(
            domainId: $domainId
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            apiDomains {
                _id
                url
                index
                created
            }
        }
    }
`;

export const ADD_FILE_TO_KNOWLEDGE_BASE = gql`
	mutation addFileToKnowledgeBase(
		$enterpriseOrganizationId: ID!
		$fileKeyS3: String!
		$name: String!
		$type: String!
	) {
		addFileToKnowledgeBase(
			enterpriseOrganizationId: $enterpriseOrganizationId
			fileKeyS3: $fileKeyS3
			name: $name
			type: $type
		) {
			${knowledgeBaseFile}
		}
	}
`;

export const REMOVE_FILE_FROM_KNOWLEDGE_BASE = gql`
    mutation removeFileFromKnowledgeBase(
        $fileId: ID!
        $enterpriseOrganizationId: ID!
    ) {
        removeFileFromKnowledgeBase(
            fileId: $fileId
            enterpriseOrganizationId: $enterpriseOrganizationId
        )
    }
`;

export const ACTIVATE_FILE_FOR_KNOWLEDGE_BASE = gql`
	mutation activateFileForKnowledgeBase(
		$enterpriseOrganizationId: ID!
		$fileId: ID!
	) {
		activateFileForKnowledgeBase(
			enterpriseOrganizationId: $enterpriseOrganizationId
			fileId: $fileId
		) {
			${knowledgeBaseFile}
		}
	}
`;

export const DEACTIVATE_FILE_FOR_KNOWLEDGE_BASE = gql`
	mutation deactivateFileForKnowledgeBase(
		$enterpriseOrganizationId: ID!
		$fileId: ID!
	) {
		deactivateFileForKnowledgeBase(
			enterpriseOrganizationId: $enterpriseOrganizationId
			fileId: $fileId
		) {
			${knowledgeBaseFile}
		}
	}
`;

export const TAG_COLLEAGUE = gql`
    mutation tagColleague(
        $enterpriseOrganizationId: ID!
        $projectId: ID!
        $userEmail: String!
        $comment: String
        $messageId: ID
    ) {
        tagColleague(
            enterpriseOrganizationId: $enterpriseOrganizationId
            userEmail: $userEmail
            projectId: $projectId
            comment: $comment
            messageId: $messageId
        )
    }
`;

export const DELETE_ORGANIZATION = gql`
    mutation deleteOrganization($enterpriseOrganizationId: ID!) {
        deleteOrganization(enterpriseOrganizationId: $enterpriseOrganizationId)
    }
`;

export const ADD_DEFAULT_TECH_STACK_ITEM_TO_ENTERPRISE_ORGANIZATION = gql`
    mutation addDefaultTechStackItemToEnterpriseOrganization(
        $name: String!
        $enterpriseOrganizationId: ID!
    ) {
        addDefaultTechStackItemToEnterpriseOrganization(
            name: $name
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            ${defaultTechStackItem}
        }
    }
`;

export const REMOVE_DEFAULT_TECH_STACK_ITEM_FROM_ENTERPRISE_ORGANIZATION = gql`
    mutation removeDefaultTechStackItemFromOrganization(
        $defaultTechStackItemId: ID!
        $enterpriseOrganizationId: ID!
    ) {
        removeDefaultTechStackItemFromOrganization(
            defaultTechStackItemId: $defaultTechStackItemId
            enterpriseOrganizationId: $enterpriseOrganizationId
        )
    }
`;

export const TRANSFER_PROJECT = gql`
    mutation transferProject(
        $projectId: ID!
        $fromEnterpriseOrganizationId: ID!
        $toEnterpriseOrganizationId: ID!
    ) {
        transferProject(
            projectId: $projectId
            fromEnterpriseOrganizationId: $fromEnterpriseOrganizationId
            toEnterpriseOrganizationId: $toEnterpriseOrganizationId
        )
    }
`;

export const SYNC_JIRA = gql`
    mutation syncJira(
        $projectId: ID!
        $version: Int!
        $jiraProjectId: String
        $skillLevel: String
    ) {
        syncJira(
            projectId: $projectId
            version: $version
            jiraProjectId: $jiraProjectId
            skillLevel: $skillLevel
        )
    }
`;

export const SYNC_LINEAR = gql`
    mutation syncLinear(
        $projectId: ID!
        $version: Int!
        $linearProjectId: String
        $skillLevel: String
    ) {
        syncLinear(
            projectId: $projectId
            version: $version
            linearProjectId: $linearProjectId
            skillLevel: $skillLevel
        )
    }
`;

export const ADD_SEATS_TO_ENTERPRISE_ORGANIZATION = gql`
    mutation addSeatsToEnterpriseOrganization(
        $enterpriseOrganizationId: ID!
        $seatsToAdd: Int!
    ) {
        addSeatsToEnterpriseOrganization(
            enterpriseOrganizationId: $enterpriseOrganizationId
            seatsToAdd: $seatsToAdd
        )
    }
`;

export const CREATE_ENTERPRISE_PAYMENT_LINK_WITH_QUANTITY_AND_DURATION = gql`
    mutation createEnterprisePaymentLinkWithQuantityAndDuration(
        $enterpriseOrganizationId: ID!
        $duration: String!
        $quantity: Int!
        $addOns: [String]
    ) {
        createEnterprisePaymentLinkWithQuantityAndDuration(
            enterpriseOrganizationId: $enterpriseOrganizationId
            duration: $duration
            quantity: $quantity
            addOns: $addOns
        ) {
            ${enterprise_subscription_link}
        }
    }
`;

export const REMOVE_USER_SKILL_FROM_ENTERPRISE_ORGANIZATION = gql`
    mutation removeUserSkillFromEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
        $skillIndex: Int!
    ) {
        removeUserSkillFromEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
            skillIndex: $skillIndex
        ) {
            ${enterprise}
        }
    }
`;

export const ADD_USER_SKILL_TO_ENTERPRISE_ORGANIZATION = gql`
    mutation addUserSkillToEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
        $skill: SkillInput!
    ) {
        addUserSkillToEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
            skill: $skill
        ) {
            ${enterprise}
        }
    }
`;

export const UPDATE_USER_HOURLY_RATE_OF_ENTERPRISE_ORGANIZATION = gql`
    mutation updateUserHourlyRateOfEnterpriseOrganization(
        $userEmail: String!
        $enterpriseOrganizationId: ID!
        $hourlyRate: Int!
    ) {
        updateUserHourlyRateOfEnterpriseOrganization(
            userEmail: $userEmail
            enterpriseOrganizationId: $enterpriseOrganizationId
            hourlyRate: $hourlyRate
        ) {
            ${enterprise}
        }
    }
`;

export const ENABLE_LEAD_GEN = gql`
    mutation enableLeadGen($projectId: ID!, $value: Boolean!) {
        enableLeadGen(projectId: $projectId, value: $value) {
            ${project}
        }
    }
`;

export const REVEAL_PROJECT = gql`
    mutation revealProject($projectId: ID!, $enterpriseOrganizationId: ID!) {
        revealProject(projectId: $projectId, enterpriseOrganizationId: $enterpriseOrganizationId) {
            ${project}
        }
    }
`;

export const TRIGGER_LEAD_GEN_MEETING = gql`
    mutation triggerLeadGenMeeting($projectId: ID!, $enterpriseOrganizationId: ID!) {
        triggerLeadGenMeeting(projectId: $projectId, enterpriseOrganizationId: $enterpriseOrganizationId) {
            ${project}
        }
    }
`;

export const UPGRADE_TO_LEAD_GEN_TIER = gql`
    mutation upgradeToLeadGenTier($enterpriseOrganizationId: ID!, $newTier: String!) {
        upgradeToLeadGenTier(enterpriseOrganizationId: $enterpriseOrganizationId, newTier: $newTier) {
            ${enterprise}
        }
    }
`;

export const UPGRADE_TO_WIDGET_MANAGEMENT = gql`
    mutation upgradeToWidgetManagement($enterpriseOrganizationId: ID!) {
        upgradeToWidgetManagement(enterpriseOrganizationId: $enterpriseOrganizationId) {
            ${enterprise}
        }
    }
`;

export const PURCHASE_LEAD_GEN_CREDITS = gql`
    mutation purchaseLeadGenCredits($enterpriseOrganizationId: ID!, $quantity: Int!) {
        purchaseLeadGenCredits(enterpriseOrganizationId: $enterpriseOrganizationId, quantity: $quantity) {
            ${enterprise}
        }
    }
`;
