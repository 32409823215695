import { useState, useEffect, useRef } from 'react';
import { submitHeroExperience } from '../../../redux/actions/solo/submitHeroExperience';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { RandomButtons } from './random_buttons';
import { BudgetSlider } from '../../CreateNewProject/BudgetSlider';
import { setShowRequestModal } from '../../../redux/reducers/generic/project';

export const InputArea = ({
    answers,
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex
}) => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const [matchWithDev, setMatchWithDev] = useState(true);
    const [budget, setBudget] = useState(10000);
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const dispatch = useDispatch();

    useEffect(() => {
        if (userEmail && questionIndex === 2) setAnswer(userEmail);
    }, [questionIndex, userEmail]);

    const processAnswer = async () => {
        if (answer.length < 1) {
            toast('Please enter an answer.');
            return;
        }
        setQuestionIndex(questionIndex + 1);

        if (questionIndex === 2) {
            const email = answer.trim();
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setAnswer('');
            if (!emailPattern.test(email)) {
                toast('Please enter a valid email address.');
                return;
            }
            setShowInput(false);
            setLoading(true);
            let countdownTime = 5;
            setCountdown(countdownTime);

            let project_id;

            if (window.innerWidth <= 768) {
                document.activeElement.blur();
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }, 300);
            }

            try {
                let countInterval = setInterval(() => {
                    if (countdownTime > 0) {
                        countdownTime--;
                        setCountdown(countdownTime);
                    } else if (project_id) {
                        navigate('/demo/' + project_id);
                        clearInterval(countInterval);
                        if (matchWithDev) {
                            setTimeout(() => {
                                dispatch(setShowRequestModal(true));
                            }, 20000);
                        }
                    }
                }, 1000);

                try {
                    project_id = await dispatch(
                        submitHeroExperience({
                            answers: answers,
                            userEmail: email,
                            matchWithDev,
                            budget: budget.toString()
                        })
                    );
                    dispatch(getProjectByLink(project_id));
                } catch (e) {
                    navigate('/auth');
                }
            } catch (e) {
                navigate('/auth');
            }
        } else {
            setShowInput(false);
            setAnswers(prevAnswers => [...prevAnswers, answer]);
        }
    };

    const placeholderText =
        questionIndex === 0
            ? 'Generate a development plan in seconds...'
            : questionIndex === 1
              ? 'Ex: they see a customer analytics dashboard...'
              : 'Email address';

    let textAreaHeight = 'h-[200px]';
    if (questionIndex === 2) textAreaHeight = 'h-[80px]';

    return (
        <>
            <MainInput
                answer={answer}
                setAnswer={setAnswer}
                questionIndex={questionIndex}
                placeholderText={placeholderText}
                textAreaHeight={textAreaHeight}
                processAnswer={processAnswer}
            />
            <div className="flex flex-col md:flex-row items-center justify-between">
                {questionIndex === 2 && (
                    <div>
                        <div className="flex items-center mt-4 border border-gray-500 p-4 rounded-lg">
                            <input
                                type="checkbox"
                                id="matchWithDev"
                                checked={matchWithDev}
                                onChange={() => setMatchWithDev(!matchWithDev)}
                                className="mr-2"
                            />
                            <label
                                htmlFor="matchWithDev"
                                className="text-green-300"
                            >
                                <span className="font-medium">
                                    Match me with a dev
                                </span>
                                <p className="text-xs text-green-500">
                                    Allow developer agencies to contact me about
                                    my project
                                </p>
                            </label>
                        </div>
                        <div className="mt-4 max-w-sm">
                            <BudgetSlider
                                textColor="text-white"
                                budget={budget}
                                setBudget={setBudget}
                            />
                        </div>
                    </div>
                )}
                <div className="flex flex-row items-center justify-between w-full">
                    <RandomButtons
                        answer={answer}
                        answers={answers}
                        setAnswer={setAnswer}
                        questionIndex={questionIndex}
                    />
                    <button
                        onClick={processAnswer}
                        className="flex gap-x-2 items-center text-center rounded-lg cursor-pointer right-0 text-slate-400 text-xs bg-gray-800 hover:bg-gray-700 rounded py-2 px-4 transition duration-200"
                        title="Press Enter or click here to submit"
                    >
                        Press Enter to submit
                    </button>
                </div>
            </div>
        </>
    );
};
const MainInput = ({
    answer,
    setAnswer,
    questionIndex,
    placeholderText,
    processAnswer,
    textAreaHeight
}) => {
    const textareaRef = useRef(null);

    const handleFocus = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 200);
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (
    //             window.visualViewport &&
    //             window.visualViewport.height < window.innerHeight
    //         ) {
    //             handleFocus();
    //         }
    //     };

    //     window.addEventListener('resize', handleResize);
    // }, []);

    return (
        <textarea
            ref={textareaRef}
            type={questionIndex === 2 ? 'email' : 'text'}
            rows="4"
            required
            value={answer}
            onChange={e => setAnswer(e.target.value)}
            // onFocus={handleFocus}
            className={`text-green-300 w-[calc(100%-2rem)] ${textAreaHeight} md:pl-8 md:mx-4 border-transparent focus:border-transparent focus:ring-0 font-light terminal-input text-left ${
                answer.length < 50
                    ? 'md:text-5xl text-3xl'
                    : answer.length < 100
                      ? 'md:text-4xl text-2xl'
                      : answer.length < 150
                        ? 'md:text-3xl text-xl'
                        : 'md:text-2xl text-lg'
            }`}
            placeholder={placeholderText}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    processAnswer();
                }
            }}
        />
    );
};
