import { MegaphoneIcon, WindowIcon } from '@heroicons/react/24/outline';

export let basicPlan = {
    id: 'basic',
    name: 'Basic',
    description:
        'A basic free plan for individuals or teams to try the platform',
    features: [
        'No credit card required',
        '2 project limit',
        '10 responses max per project',
        'Automatic dev agency matching',
        'Budget Estimation & Optimization'
    ],
    missingFeatures: [
        'Unlimited Projects',
        'Unlimited Documentation & Roadmap Generations',
        'Jira/Linear Integration and Sync',
        'UI/UX Generation'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

export let basicPlanEnterprise = {
    id: 'basic',
    name: 'Basic',
    description:
        'A basic free plan for individuals or teams to try the platform',
    features: [
        'No credit card required',
        '2 project limit',
        '10 responses max per project',
        'Budget Estimation & Optimization'
    ],
    missingFeatures: [
        'Unlimited Projects',
        'Unlimited Documentation & Roadmap Generations',
        'Jira/Linear Integration and Sync',
        'UI/UX Generation',
        'Lead Generation'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

let soloPlusPlan = {
    id: 'SoloPlus',
    name: 'Solo Plus',
    description: 'An intro plan for individuals',
    features: [
        'Unlimited Projects',
        'Full Documentation Generation',
        'Full Roadmap Generation',
        'Priority Access to Dev Agencies'
    ],
    monthlyPrice: 1400,
    yearlyPrice: 14000,
    textColor: 'text-blue-400'
};

export let soloPremiumPlan = {
    id: 'SoloPremium',
    name: 'Solo Premium',
    description: 'The ultimate plan for individuals',
    features: [
        'Everything in Solo Plus +',
        'Jira/Linear Integration and Sync',
        'UI/UX Generations'
    ],
    monthlyPrice: 4900,
    yearlyPrice: 49900,
    textColor: 'text-blue-500'
};

let enterprisePremiumPlan = {
    id: 'EnterprisePremium',
    name: 'Enterprise Premium',
    description: 'An enterprise plan made for product teams',
    features: [
        'Everything in solo premium +',
        'Advanced collaboration features',
        'Configurable knowledge base',
        'Data security and compliance'
    ],
    addons: [
        'Project Scoping Widget',
        'Lead Generation',
        'Auto Assign Team Members to Tasks'
    ],
    stripeAddOns: [
        {
            id: 'WidgetManagement',
            price: 249,
            title: 'Widget Management',
            description:
                'Seamlessly integrate pre.dev scoping widget to streamline client onboarding directly on your website',
            icon: textColor => (
                <WindowIcon
                    className={`h-5 w-5 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        },
        {
            id: 'LeadGenPlus',
            price: 499,
            title: 'Lead Gen + Widget Management',
            description:
                '50 credits/month to view and connect with qualified leads, including formal introductions by pre.dev',
            icon: textColor => (
                <MegaphoneIcon
                    className={`h-5 w-5 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        }
        // {
        //     id: 'AutoAssignTeamMembers',
        //     price: 499,
        //     title: 'Auto Assign Team Members',
        //     description:
        //         'Auto assign team members to tasks based on their skills and availability'
        // }
    ],
    monthlyPrice: 9900,
    yearlyPrice: 99900,
    textColor: 'text-green-500',
    perUser: true
};

export const prices = ({ disabledBasic, disabledSolo, disableEnterprrise }) => {
    let plans = [];

    if (!disabledBasic) {
        plans.push(basicPlan);
    }

    if (!disabledSolo) {
        plans.push(soloPlusPlan, soloPremiumPlan);
    }

    if (!disableEnterprrise) {
        plans.push(enterprisePremiumPlan);
    }

    return plans;
};
