import gql from 'graphql-tag';
import { enterprise, project } from '../helper';
export const GET_ENTERPRISE_ORGANIZATIONS_BY_USER = gql`
    query enterpriseOrganizationsByUser {
        enterpriseOrganizationsByUser {
            _id
            name
            created
        }
    }
`;

export const GET_CLIENT_JIRA_PROJECTS = gql`
    query getClientJiraProjects {
        getClientJiraProjects {
            id
            name
        }
    }
`;

export const GET_CLIENT_LINEAR_PROJECTS = gql`
    query getClientLinearProjects {
        getClientLinearProjects {
            id
            name
        }
    }
`;

export const GET_ENTERPRISE_ORGANIZATION_BY_USER = gql`
	query enterpriseOrganizationByUser($enterpriseOrganizationId: ID!) {
		enterpriseOrganizationByUser(
			enterpriseOrganizationId: $enterpriseOrganizationId
		) {
			${enterprise}
		}
	}
`;

export const GET_PROJECTS_BY_ENTERPRISE_ORGANIZATION = gql`
    query projectsByEnterpriseOrganization(
        $enterpriseOrganizationId: ID!
        $skip: Int!
        $limit: Int!
        $searchQuery: String
        $creationMethodFilter: String
        $sort: Sort
        $matchWithDevsOnly: Boolean
        $ignorePreDevEmails: Boolean
    ) {
        projectsByEnterpriseOrganization(
            enterpriseOrganizationId: $enterpriseOrganizationId
            skip: $skip
            limit: $limit
            searchQuery: $searchQuery
            creationMethodFilter: $creationMethodFilter
            sort: $sort
            matchWithDevsOnly: $matchWithDevsOnly
            ignorePreDevEmails: $ignorePreDevEmails
        ) {
            projects {
                _id
                leadGenEnabled
                name
                type
                created
                emailVerified
                updated
                meetingRequested
                country
                userEmail
                budget
                creationMethod
                messageCount
                matchWithDev
            }
            totalProjects
        }
    }
`;

export const GET_PROJECT_BY_ENTERPRISE_ORGANIZATION = gql`
	query projectByEnterpriseOrganization(
		$projectId: ID!
		$enterpriseOrganizationId: ID!
	) {
		projectByEnterpriseOrganization(
			projectId: $projectId
			enterpriseOrganizationId: $enterpriseOrganizationId
		) {
			${project}
		}
	}
`;

export const ENTERPRISE_STRIPE_LINK = gql`
    query enterpriseStripeLink($enterpriseOrganizationId: ID!) {
        enterpriseStripeLink(
            enterpriseOrganizationId: $enterpriseOrganizationId
        )
    }
`;

export const GET_PROJECT_BY_LEAD_GEN = gql`
    query projectByLeadGen(
        $enterpriseOrganizationId: ID!
        $projectId: ID!
    ) {
        projectByLeadGen(
            enterpriseOrganizationId: $enterpriseOrganizationId
            projectId: $projectId
        ) {
            ${project}
        }
    }
`;

export const GET_PROJECTS_BY_LEAD_GEN = gql`
    query projectsByLeadGen(
        $enterpriseOrganizationId: ID!
        $limit: Int!
        $skip: Int!
        $searchQuery: String
        $sort: Sort
        $onlyRevealedProjects: Boolean
    ) {
        projectsByLeadGen(
            enterpriseOrganizationId: $enterpriseOrganizationId
            limit: $limit
            skip: $skip
            searchQuery: $searchQuery
            sort: $sort
            onlyRevealedProjects: $onlyRevealedProjects
        ) {
            projects {
                _id
                name
                created
                userEmail
                country
                updated
                budget
                messageCount
                matchWithDev
                leadGenEmailRevealed
                leadGenMeetingTriggered
                execSummary
            }
            totalProjects
        }
    }
`;
