import { Download } from './download';
import { GenerateLatestCustomSow } from './generate_latest_custom_sow';
import { HideHours } from './hide_hours';
import { ModeToggle } from './ModeToggle';
import SkillLevel from './skill_level';
import { Icon } from '../TipTap/components/ui/Icon';
import { Toolbar } from '../TipTap/components/ui/Toolbar';
import { Editor } from '@tiptap/core';
import { useSelector } from 'react-redux';
import {
    selectEditMode,
    selectHideHours,
    selectSow
} from '../../../../redux/reducers/generic/sow';
import { Save } from './save';
import { Loading } from './Loading';
import { TopHeader } from '../TopHeader';
import { selectSidebarOpen } from '../../../../redux/reducers/generic/sidebar';
import { useState, useEffect } from 'react';
import { PricingEstimate } from './PricingEstimate';
import { TechStackButton } from './TechStack';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { UpgradeToPremiumButton } from './UpgradeToPremium';

export type EditorHeaderProps = {
    isScrolledToTop: boolean;
    editor: Editor;
    isSidebarOpen?: boolean;
    toggleSidebar?: () => void;
    isEnterprise?: boolean;
};

export const EditorHeader = ({
    isScrolledToTop,
    editor,
    isSidebarOpen,
    toggleSidebar,
    isEnterprise
}: EditorHeaderProps) => {
    let sow = useSelector(selectSow);
    let hide_hours = useSelector(selectHideHours);
    let isEditMode = useSelector(selectEditMode);
    const sidebarOpen = useSelector(selectSidebarOpen);
    const [zIndex, setZIndex] = useState('');
    const { isShare, isSubbed } = useSubscriptionPayment();

    useEffect(() => {
        if (!sidebarOpen) {
            const timer = setTimeout(() => {
                setZIndex('z-[1]');
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setZIndex('');
        }
    }, [sidebarOpen]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(
                window.navigator.userAgent.match(
                    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
                ) !== null
            );
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    return (
        <div
            className={`top-0 sticky left-0 right-0 flex flex-col items-center justify-between flex-none pb-2 text-black bg-white ${zIndex}`}
        >
            <TopHeader
                isEnterprise={isEnterprise}
                isSidebarOpen={isSidebarOpen}
            />
            <div className="flex flex-row items-center flex-none justify-between w-full mt-2 px-4">
                <div className="flex items-center gap-x-2">
                    <div className="hidden md:block">
                        <Toolbar.Button
                            tooltip={
                                isSidebarOpen ? 'Close sidebar' : 'Open sidebar'
                            }
                            onClick={toggleSidebar}
                            active={isSidebarOpen}
                            className={isSidebarOpen ? 'bg-transparent' : ''}
                        >
                            <Icon
                                name={
                                    isSidebarOpen
                                        ? 'PanelLeftClose'
                                        : 'PanelLeft'
                                }
                            />
                        </Toolbar.Button>
                    </div>
                    <Save editor={editor} isEditMode={isEditMode} />
                    {!isEditMode && <HideHours editor={editor} />}
                    {!isEditMode && sow?.completed && <TechStackButton />}
                    {/* {!isEditMode && <PricingEstimate showText={false} />} */}
                </div>
                <div className="flex items-center">
                    <div className="flex items-center space-x-2">
                        <UpgradeToPremiumButton />
                        <Download editor={editor} />
                        {sow?.type === 'custom' && <GenerateLatestCustomSow />}
                        <Loading />
                        {isSubbed &&
                            (!isMobile || isScrolledToTop) &&
                            sow?.type === 'mvp' && <ModeToggle />}
                    </div>
                </div>
            </div>
        </div>
    );
};
