import { FaDice } from 'react-icons/fa';
import { useSubscription } from '@apollo/client';
import { HERO_RANDOM_EXPERIENCE_SUBSCRIPTION } from '../../../api/graphQL';
import { v4 as uuidv4 } from 'uuid';
import { initiateHeroRandomExperience } from '../../../redux/actions/enterprise_widget/initiateHeroRandomExperience';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

export const RandomButtons = ({
    answer,
    questionIndex,
    setAnswer,
    answers
}) => {
    const [randId, setRandId] = useState(null);
    const [isRandomButtonDisabled, setIsRandomButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    useSubscription(HERO_RANDOM_EXPERIENCE_SUBSCRIPTION, {
        variables: { randId },
        skip: !randId,
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData?.data?.heroRandomExperienceStream) {
                const { suggestion, completed } =
                    subscriptionData.data.heroRandomExperienceStream;
                setAnswer(suggestion);
                if (completed) {
                    setIsRandomButtonDisabled(false);
                }
            }
        }
    });

    const randomIdea = async answer => {
        setIsRandomButtonDisabled(true);
        let rand = uuidv4();
        setRandId(rand);
        try {
            let { suggestion } = await dispatch(
                initiateHeroRandomExperience({
                    randId: rand,
                    answers,
                    inspiration: answer
                })
            );
            setAnswer(suggestion);
            setIsRandomButtonDisabled(false);
        } catch (error) {
            console.error('Error in randomIdea:', error.message);
            setIsRandomButtonDisabled(false);
        }
    };

    return (
        <div className="flex flex-col sm:flex-row text-xs  gap-x-4 gap-y-4">
            {questionIndex <= 1 && (
                <button
                    onClick={() => randomIdea(answer)}
                    className={`flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-3 rounded-full shadow-lg ${
                        isRandomButtonDisabled
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:shadow-xl hover:scale-105 transition duration-200'
                    }`}
                    disabled={isRandomButtonDisabled}
                >
                    {questionIndex === 0 && <FaDice className="w-5 h-5" />}
                    <span>
                        {answer
                            ? window.innerWidth < 768
                                ? 'Elaborate on Idea'
                                : 'Elaborate on Idea'
                            : questionIndex === 1
                              ? 'Answer for me'
                              : 'Random Idea'}
                    </span>
                </button>
            )}
            {questionIndex === 0 && answer?.length > 0 && (
                <button
                    onClick={() => randomIdea(null)} // Assuming you want to generate a new idea without an answer
                    className={`flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-green-500 text-white font-bold py-2 px-4 rounded-full ${
                        isRandomButtonDisabled
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:shadow-xl hover:scale-105 transition duration-200'
                    }`}
                    disabled={isRandomButtonDisabled}
                >
                    <FaDice className="w-5 h-5" />
                    <span>New Idea</span>
                </button>
            )}
        </div>
    );
};
