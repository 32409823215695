import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../Components/Modal';
import {
    selectShowAdvancedConfiguration,
    toggleAdvancedConfiguration
} from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { ToggleField } from '../Helpers/ToggleField';
import { InputField } from '../Helpers/InputField';
import { ImageField } from '../Helpers/ImageField';

export const AdvancedConfigModal = ({}) => {
    const dispatch = useDispatch();
    const showAdvancedConfigModal = useSelector(
        selectShowAdvancedConfiguration
    );

    return (
        <Modal
            open={showAdvancedConfigModal}
            onClose={() => {
                dispatch(toggleAdvancedConfiguration());
            }}
            maxWidth="max-w-2xl"
            title="Advanced Configuration"
        >
            <div className="flex flex-col gap-y-3">
                <div className="flex flex-row gap-4 justify-between">
                    <ImageField field="logo" title="Header Logo" />
                    <ImageField field="chatAgentLogo" title="Chat Agent Logo" />
                </div>
                <ToggleField
                    field="disableShareLink"
                    text="Disable Project Completion Email to Client"
                />
                <ToggleField
                    field="disableNewProjectEmails"
                    text="Disable enterprise user notification emails upon new project creation"
                />
                <ToggleField
                    field="disableViewProjectPlanWidget"
                    text="Disable ability to view project plan from widget"
                />
                <ToggleField
                    field="disableHoursInShareLink"
                    text="Disable hours in share link"
                />
                <InputField
                    field="chatAgentName"
                    label="Chat Agent Name"
                    placeholder="pre.dev"
                />
                <InputField
                    field="projectCreationTitle"
                    label="Project Creation Title"
                    placeholder="start with a idea"
                />
                <InputField
                    field="initialChatMessage"
                    label="Initial Chat Message"
                    isTextArea={true}
                    placeholder="Hello, I'm going to help narrow down the technical specifications of your project with a few preliminary questions. First, could you please provide some initial information about the software you are looking to build?"
                />
                <InputField
                    field="clientEmail"
                    label={`Email Copy (in HTML) for Client upon Project Completion use LINK as a substitute of link to the project`}
                    isTextArea={true}
                    placeholder={
                        '<p>Click the link below to see your project:</p><p><a href="LINK">LINK</a></p>'
                    }
                />
            </div>
        </Modal>
    );
};
