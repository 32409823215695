import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOnlyRevealedProjects } from '../../../redux/reducers/generic/projects';
import { RadioGroup } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const FilterBox = () => {
    const dispatch = useDispatch();
    const creationSelections = [
        { id: null, title: 'All Projects' },
        { id: 'revealed', title: 'Revealed Projects' }
    ];
    const [selectedOption, setSelectedOption] = useState(
        creationSelections[0].id
    );

    const onChange = id => {
        setSelectedOption(id);
        dispatch(setOnlyRevealedProjects(id === 'revealed'));
    };

    return (
        <div className="w-fit flex flex-row gap-x-4 gap-y-4 items-center bg-gray-50 border border-gray-300 rounded-md px-4 py-2 text-sm font-medium">
            {/* <div className="flex items-center justify-between">
                <h2 className="text-xs font-medium text-gray-900">Filter</h2>
            </div> */}

            <RadioGroup value={selectedOption} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                    Choose a filter option
                </RadioGroup.Label>
                <div className="flex items-center space-x-4">
                    {creationSelections.map(option => (
                        <RadioGroup.Option
                            key={option.id}
                            value={option.id}
                            className={({ active, checked }) =>
                                classNames(
                                    'cursor-pointer focus:outline-none',
                                    active
                                        ? 'ring-2 ring-blue-600 ring-offset-2'
                                        : '',
                                    checked
                                        ? 'bg-blue-600 text-white hover:bg-blue-500'
                                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-md px-4 py-0.5 text-sm font-semibold'
                                )
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <input
                                        id={option.id}
                                        name="filter"
                                        type="radio"
                                        value={option.id}
                                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 sr-only"
                                    />
                                    <label
                                        htmlFor={option.id}
                                        className={classNames(
                                            'block text-xs font-medium leading-6',
                                            checked
                                                ? 'text-white'
                                                : 'text-gray-900'
                                        )}
                                    >
                                        {option.title}
                                    </label>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
};
