import { chatEndingIndex, getChatEndingIndex } from '../../../constants';
import { useProject } from '../../../context-providers/Project';
import { selectGraphVersion } from '../../../redux/reducers/generic/projectArchitecture';
import { Budget } from '../Budget';
import { Placeholder } from './Placeholder';
import App from './ReactFlowAuto/App';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const ProjectArchitecture = ({ noBudget }) => {
    const { chatHistory, project, projectArchitecture, graphType, pageType } =
        useProject();

    const graphVersion = useSelector(selectGraphVersion);
    const currentIndex = getChatEndingIndex(chatHistory.data);

    const graph = projectArchitecture.graphs.find(g => g.side === graphType);

    const showPlaceholder = project.skip
        ? false
        : currentIndex < chatEndingIndex;

    return (
        <div className="flex flex-col h-full w-full">
            {!noBudget && <Budget />}
            <div
                className="w-full h-full"
                id="graph_side"
                style={{ backgroundColor: 'transparent' }}
            >
                {graph && !showPlaceholder ? (
                    <App
                        key={`${project._id}-${graphVersion}-${graphType}-${pageType}`}
                        nodes={graph.nodes}
                        edges={graph.edges}
                    />
                ) : showPlaceholder ? (
                    <Placeholder />
                ) : null}
            </div>
        </div>
    );
};
