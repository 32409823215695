import { GET_PROJECTS_BY_LEAD_GEN } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectsRequest,
    fetchProjectsSuccess,
    fetchProjectsFailure,
    fetchPaginatedProjects
} from '../../reducers/generic/projects';
import { projectsLimit } from '../../../constants';

// Action Creators
export const getProjectsByLeadGen =
    ({
        enterpriseOrganizationId,
        skip = 0,
        limit = projectsLimit,
        searchQuery,
        sort,
        onlyRevealedProjects
    }) =>
    async dispatch => {
        dispatch(fetchProjectsRequest());
        try {
            const { data } = await client.query({
                query: GET_PROJECTS_BY_LEAD_GEN,
                variables: {
                    enterpriseOrganizationId,
                    skip,
                    limit,
                    searchQuery,
                    sort,
                    onlyRevealedProjects
                },
                fetchPolicy: 'network-only'
            });
            if (skip > 0) {
                dispatch(
                    fetchPaginatedProjects(data.projectsByLeadGen.projects)
                );
            } else {
                dispatch(fetchProjectsSuccess(data.projectsByLeadGen));
            }
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectsFailure(error));
        }
    };
