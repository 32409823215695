export const chatEndingIndex = 2;

export const getChatEndingIndex = data => {
    return Math.max(Math.round(data.length / 2) - 1, 0);
};

export const setRedirectURL = (url: string) => {
    localStorage.setItem('redirectURL', url);
};

export const getRedirectURL = presetURL => {
    if (presetURL) return presetURL;
    let url = localStorage.getItem('redirectURL');
    return url ? url : '/projects';
};

export const freeTrialActivated = false;

export const projectsLimit = 20;

export const soloTrialLimit = 2;

export const getPrivacyPolicyURL = () => {
    return 'https://app.termly.io/document/privacy-policy/001064ff-9086-48f5-aebe-68ee93343415';
};

export const getTermsOfServiceURL = () => {
    return 'https://app.termly.io/document/terms-of-service/cca10b20-556c-4cf1-ad82-bc030a653363';
};

export const getAcceptableUsePolicyURL = () => {
    return 'https://app.termly.io/document/acceptable-use/ab3fc585-c95d-40d6-83b7-8fa12c214270';
};

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export function capitalizeGraphs(input: string): string {
    return input
        ?.split(' ')
        ?.map(
            word => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
        )
        ?.join(' ');
}

export const projectTypes = [
    {
        id: 1,
        type: 'web2',
        title: 'web / mobile',
        description:
            'For classic mobile apps/websites where data is stored on central servers.'
    },
    {
        id: 2,
        type: 'web3',
        title: 'blockchain',
        description:
            'For projects that use the blockchain, where data is shared across a network.'
    },
    {
        id: 3,
        type: 'other',
        title: 'any',
        description:
            'For other projects such as ERPS, scripts/static codebases, wordpress sites, data pipelines, etc'
    }
];

export const reload = () => {
    try {
        const reloadKey = 'lastReloadTimestamps';
        const now = Date.now();
        const oneMinute = 60000; // milliseconds
        const reloadTimestamps =
            JSON.parse(localStorage.getItem(reloadKey)) || [];

        // Filter out timestamps older than a minute
        const recentTimestamps = reloadTimestamps.filter(
            timestamp => now - timestamp < oneMinute
        );

        if (recentTimestamps.length < 2) {
            recentTimestamps.push(now);
            localStorage.setItem(reloadKey, JSON.stringify(recentTimestamps));
            window.location.href = window.location.href;
        }
    } catch (e) {
        console.log(e);
    }
};

export const salesCalendlyLink = `https://calendly.com/pre_dev/talk-to-sales`;
export const requestDevelopersCalendlyLink = `https://calendly.com/pre_dev/request-developers`;
