import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SparklesText from '../../Components/magicui/SparklesText';
import WordPullUp from '../../Components/magicui/WordPullUp';
import { Loader2 } from 'lucide-react';
import { InputArea } from './input_area';

export const QuestionExperience = ({ questionIndex, setQuestionIndex }) => {
    const [showInput, setShowInput] = useState(true);
    const [restartAnimation, setRestartAnimation] = useState(false);
    const [showTextarea, setShowTextarea] = useState(false);
    const [startTextUp, setStartTextUp] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [loading, setLoading] = useState(null);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        if (!showInput) {
            //document.body.classList.add('no-scroll');
            const timeout = setTimeout(() => {
                setRestartAnimation(true);
                setShowInput(true);
                setShowTextarea(false);
                setStartTextUp(false);
                const restartTimeout = setTimeout(() => {
                    setRestartAnimation(false);
                }, 100); // Short duration to trigger the re-render
                return () => clearTimeout(restartTimeout);
            }, 500); // Duration of the exit animation
            return () => clearTimeout(timeout);
        }
    }, [showInput]);

    const handleWordPullUpAnimationEnd = () => {
        setStartTextUp(true);
        setTimeout(() => {
            setShowTextarea(true);
        }, 0.5);
    };

    return (
        <AnimatePresence>
            {!restartAnimation && showInput && (
                <motion.div
                    initial={{
                        opacity: 1,
                        y: 0
                    }}
                    animate={{
                        opacity: 1,
                        y: 0
                    }}
                    exit={{
                        opacity: 0,
                        y: '30vh'
                    }}
                    transition={{
                        duration: 0.5
                    }}
                    className="relative"
                >
                    <QuestionArea
                        handleWordPullUpAnimationEnd={
                            handleWordPullUpAnimationEnd
                        }
                        startTextUp={startTextUp}
                        questionIndex={questionIndex}
                    />
                    {showTextarea && (
                        <TextArea
                            answers={answers}
                            setAnswers={setAnswers}
                            setShowInput={setShowInput}
                            setCountdown={setCountdown}
                            setLoading={setLoading}
                            setQuestionIndex={setQuestionIndex}
                            questionIndex={questionIndex}
                            loading={loading}
                            countdown={countdown}
                        />
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const QuestionArea = ({
    handleWordPullUpAnimationEnd,
    startTextUp,
    questionIndex
}) => {
    let message =
        questionIndex == 0
            ? 'What is a product or feature you would like to build today?'
            : questionIndex == 1
              ? 'Thank you. What is the first thing a user can do or see?'
              : questionIndex == 2
                ? 'Great! What email should we send your project to?'
                : 'Thank you! We will send your project to your email shortly.';

    return (
        <motion.div
            initial={{ y: 0 }}
            animate={{
                y: startTextUp ? -20 : 50
            }}
            transition={{
                duration: 0.25
            }}
            className="relative mb-4"
        >
            <SparklesText sparklesCount={8}>
                <WordPullUp
                    className="text-4xl mt-[20px] md:mt-[120px] font-medium tracking-[-0.02em] text-white dark:text-white md:text-6xl"
                    words={message}
                    onAnimationEnd={handleWordPullUpAnimationEnd}
                />
            </SparklesText>
        </motion.div>
    );
};

const TextArea = ({
    answers,
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex,
    loading,
    countdown
}) => {
    return (
        <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                duration: 1
            }}
            className="relative"
        >
            {loading ? (
                <div className="flex flex-col items-center">
                    <Loader2 className="h-16 w-16 mx-auto text-slate-50 animate-spin text-center" />
                    {countdown !== null && (
                        <div className="text-slate-400 text-sm mt-4">
                            Redirecting you in {countdown}
                            ...
                        </div>
                    )}
                </div>
            ) : (
                <InputArea
                    answers={answers}
                    setAnswers={setAnswers}
                    setShowInput={setShowInput}
                    setCountdown={setCountdown}
                    setLoading={setLoading}
                    setQuestionIndex={setQuestionIndex}
                    questionIndex={questionIndex}
                />
            )}
        </motion.div>
    );
};
