import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'sonner';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { getWebsiteDomain } from '../../../../auth_config';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { Actions } from './actions';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export function getCountryFlagEmoji(countryCode) {
    if (
        !countryCode ||
        typeof countryCode !== 'string' ||
        countryCode.length !== 2
    ) {
        return '';
    }

    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());

    return String.fromCodePoint(...codePoints);
}

export const TableItem = ({ project, setProject, bgColor, setOpen }) => {
    const navigate = useNavigate();
    const { enterpriseOrganization } = useEnterpriseOrganization();

    const { enterpriseOrganizationId } = useParams();
    const { isSubbed } = useSubscriptionPayment();

    let apiKey = enterpriseOrganization?.apiKey;

    const [showShareLink, setShowShareLink] = useState(false);

    const onClickProject = project => {
        navigate(project._id);
    };

    let shareLink = project =>
        isSubbed
            ? `${getWebsiteDomain()}/share/enterprise/${enterpriseOrganizationId}/${
                  project._id
              }`
            : `${getWebsiteDomain()}/share/${apiKey}/${project._id}`;

    function formatDate(isoDate) {
        const date = new Date(isoDate);
        const now = new Date();
        const isToday = date.toDateString() === now.toDateString();

        if (isToday) {
            return date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit'
            });
        } else {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit'
            });
        }
    }

    return (
        <tr
            className="cursor-pointer hover:bg-gray-50"
            key={project._id}
            onMouseEnter={() => setShowShareLink(true)}
            onMouseLeave={() => setShowShareLink(false)}
        >
            <td
                className="whitespace-nowrap px-2 py-3 text-sm font-medium text-gray-900"
                onClick={() => onClickProject(project)}
            >
                <div className="flex items-center space-x-3 lg:pl-2">
                    {project.country ? (
                        <span className="text-sm text-gray-500">
                            {getCountryFlagEmoji(project.country)}
                        </span>
                    ) : (
                        <div
                            className={classNames(
                                bgColor,
                                'h-2.5 w-2.5 flex-shrink-0 rounded-full'
                            )}
                            aria-hidden="true"
                        />
                    )}
                    <a
                        style={{
                            maxWidth: '100px'
                        }}
                        className="truncate hover:text-gray-600
																			"
                    >
                        <span>{project.name}</span>
                    </a>
                </div>
            </td>
            <td className="px-2 py-3 text-sm font-medium text-gray-500">
                <div className="flex items-center space-x-2">
                    <div
                        className="flex gap-x-2"
                        onClick={e => {
                            navigator.clipboard
                                .writeText(project.userEmail)
                                .then(() => {
                                    // Assuming there's a toast function available
                                    toast('User email copied to clipboard!', {
                                        type: 'success'
                                    });
                                });
                        }}
                    >
                        <span>{project.userEmail}</span>
                        <ClipboardIcon
                            className={`h-5 transition-opacity duration-300 ${
                                !showShareLink ? 'opacity-0' : ''
                            }`}
                        />
                    </div>
                </div>
            </td>
            <td
                onClick={() => onClickProject(project)}
                className="hidden whitespace-nowrap px-2 py-3 text-right text-xs text-gray-500 md:table-cell"
            >
                {formatDate(project.created)}
            </td>
            <td
                onClick={() => onClickProject(project)}
                className="hidden whitespace-nowrap px-2 py-3 text-right text-xs text-gray-500 md:table-cell"
            >
                {formatDate(project.updated)}
            </td>

            <td
                onClick={() => onClickProject(project)}
                className="hidden whitespace-nowrap px-2 py-3 text-right text-sm text-gray-500 md:table-cell"
            >
                {Math.floor(project.messageCount / 2)}
            </td>

            <td
                onClick={() => onClickProject(project)}
                className="hidden whitespace-nowrap px-2 py-3 text-right text-sm text-gray-500 md:table-cell"
            >
                {project.creationMethod}
                {project.emailVerified && (
                    <span className="ml-2 text-green-500">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 inline"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                )}
            </td>

            <td
                onClick={() => onClickProject(project)}
                className="hidden whitespace-nowrap px-2 py-3 text-right text-sm text-gray-500 md:table-cell"
            >
                {project?.budget && (
                    <>${parseInt(project?.budget)?.toLocaleString()}</>
                )}
            </td>

            <Actions
                enterpriseOrganization={enterpriseOrganization}
                project={project}
                showShareLink={showShareLink}
                shareLink={shareLink}
                setProject={setProject}
                setOpen={setOpen}
            />
        </tr>
    );
};
