export const loadingStatus = `
chat
last_chat
architecture
last_architecture
recommendedTechStack
recommendedJobRoles
last_recommendedTechStack
last_recommendedJobRoles
sow_mvp
last_sow_mvp
sow_custom
last_sow_custom
jira
last_jira
uiview
last_uiview
chatui
last_chatui
`;

export const enterprise_subscription_link = `
    url
    tier
    price
    priceId
    productId
    duration
    trial
`;

export const jira = `
    percentage
    version
    loadingText
    projectURL
`;

export const message = `
_id
nodeId
index
content
role
userEmail
datetime
completed
username
image_url
fileKeyS3
tagged
`;

export const recommended_tech_stack = `
    name
    description
    useFor
    link
    reason
    helpfulLinks {
        url
        description
    }
    alternatives {
        name
        link
        description
    }
`;

export const recommended_job_roles = `
    title
    responsibilities
    qualifications
    requiredSkills
`;

export const graph = `
nodes {
    _id
    id
    label
    hours
    description
    reactPage
    created
}
edges {
    _id
    source
    target
    description
}
_id
side
type
version
`;

export const existingProjectContext = `
    _id
    fileKeyS3
    type
    title
    text
    image_url
    summary
`;

export const project = `
_id
name
emailVerified
budget
country
type
created
updated
execSummary
userEmail
matchWithDev
firstGeneration
hasExistingContext
internal_version
skip
fileKeyS3
meetingRequested
latestTechStackVersion
latestJobRolesVersion
prices
messageCount
isSubscribed
chatHistory {
    ${message}
}
existingProjectContexts {
    ${existingProjectContext}
}
sow_mvp_up_to_date
sow_custom_up_to_date
uiview_up_to_date
recommendedTechStack {
    ${recommended_tech_stack}
}
recommendedJobRoles {
    ${recommended_job_roles}
}
graphs {
    ${graph}
}
jobRolesVersions
techStackVersions 
graphVersions
uiviewVersions
sowVersions {
    mvp
    custom
}
theme {
    aesthetic
    tailwindConfig
}
leadGenEnabled
leadGenEmailRevealed
leadGenMeetingTriggered
`;

export const enterprise_advanced_config = `
    apiKey
    calendlyLink
    websiteURL
    activateMeetings
    logo
    chatAgentLogo
    introductionLogo
    chatAgentName
    widgetCompletionRedirectURL
    projectCreationTitle
    initialChatMessage
    clientEmail
    disableNewProjectEmails
    disableViewProjectPlanWidget
    disableHoursInShareLink
    disableShareLink
    onlyNewProject
    scheduleMeetingButtonText
    scheduleMeetingHelperText
`;

export const knowledgeBaseFile = `
    _id
    name
    fileKeyS3
    created
    activated
    activatedAt
    loading
    loadingAt
    type
`;

export const enterpriseUsers = `
    enterpriseUsers {
        userEmail
        title
        role
        skills {
            title
            level
        }
        hourlyRate
        created
    }
`;

export const defaultTechStackItem = `
    _id
    name
    initialName
    link
    description
`;

export const sow = `
    projectId
    created
    title
    version
    executiveSummary
    goalsAndObjectives {
        description
        objectives
    }
    successMetrics {
        name
        description
    }

    coreFunctionalities {
        name
        description
        priority
    }
    stakeholders {
        title
        description
    }
    techStack {
        name
        useFor
    }
    milestones {
        description
        id
        stories {
            _id
            id
            active
            architectureNodeIdCitations
            description
            title
            nodeId
            acceptance_criteria
            priority
            subTasks {
                _id
                id
                storyId
                projectId
                id
                index
                description
                complexityEstimate
                hours
                roles {
                    name
                    shortHand
                }
                architectureNodeIdCitations 
                techStackItemCitations
                frontendPageCitations
            }
        }
    }
    latestTechStackVersion
    latestGraphVersion
    lastUpdatedChatIndex
    enterpriseOrganizationId
    type
    completed
    content
    internal_version
    userContent
`;

export const thumbsRating = `
    id
    rating
    metadata
`;

export const enterprise = `
	_id
	name
	isAdmin
	created
	numUsersLeft
    widgetManagementActivated
	apiKey
	apiDomains {
		_id
		url
		index
		created
	}
	${enterpriseUsers}
	subscriptionLinks {
		${enterprise_subscription_link}
	}
    defaultTechStackItems {
        ${defaultTechStackItem}
    }
	subscriptionStatus {
		status
		tier
		price
		created_at
		current_period_end
		duration
		current_period_start
		cancel_at_period_end
	}
	${enterprise_advanced_config}
    knowledgeBaseFiles {
        ${knowledgeBaseFile}
    }
    leadGenData {   
        leadGenCreditsLeft
        leadGenCreditsPurchased {
            numCredits
            createdAt
            purchaseType
        }
        leadGenSubscriptionStatus {
            status
            tier
            price
            created_at
            current_period_end
            duration
        }
    }
`;

export const leaderboardComment = `
    _id
    userId
    leaderboardEntryId
    parentCommentId
    content
    upvotes
    createdAt
    updatedAt
    isVotedByUser
    isDeleted
    isMyComment
    replies {
      _id
      userId
      leaderboardEntryId
      parentCommentId
      content
      upvotes
      createdAt
      updatedAt
      isVotedByUser
      isDeleted
      isMyComment
      replies {
        _id
        userId
        leaderboardEntryId
        parentCommentId
        content
        upvotes
        createdAt
        updatedAt
        isVotedByUser
        isDeleted
        isMyComment
        replies {
          _id
          userId
          leaderboardEntryId
          parentCommentId
          content
          upvotes
          createdAt
          updatedAt
          isVotedByUser
          isDeleted
          isMyComment
          replies {
            _id
            userId
            leaderboardEntryId
            parentCommentId
            content
            upvotes
            createdAt
            updatedAt
            isVotedByUser
            isDeleted
            isMyComment
            replies {
              _id
              userId
              leaderboardEntryId
              parentCommentId
              content
              upvotes
              createdAt
              updatedAt
              isVotedByUser
              isDeleted
              isMyComment
              replies {
                _id
                userId
                leaderboardEntryId
                parentCommentId
                content
                upvotes
                createdAt
                updatedAt
                isVotedByUser
                isDeleted
                isMyComment
              }
            }
          }
        }
      }
    }
`;

export const leaderboardEntry = `
    _id
    projectId
    name
    userId
    submittedAt
    upvotes
    commentCount
    description
    comments {
        ${leaderboardComment}
    }
    isVotedByUser
    isMyEntry
`;

export const leaderboard = `
    entries {
        _id
        projectId
        name
        userId
        submittedAt
        upvotes
        commentCount
        description
        isVotedByUser
    }
    skip
    limit
    totalEntries
    startDate
    endDate
`;
