import { useState } from 'react';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSearchLoading,
    selectSearchQuery,
    setCreationMethodFilter,
    setSearchQuery,
    setMatchWithDevsOnly,
    selectMatchWithDevsOnly,
    setIgnorePreDevEmails,
    selectIgnorePreDevEmails
} from '../../../redux/reducers/generic/projects';
import { Spinner } from '../../Components/Spinner';
import { RadioGroup } from '@headlessui/react';
import { useParams } from 'react-router-dom';

export const SearchProjects = ({ showFilter }) => {
    const dispatch = useDispatch();
    const searchLoading = useSelector(selectSearchLoading);
    const searchQuery = useSelector(selectSearchQuery);
    const [search, setSearch] = useState(searchQuery);
    const { enterpriseOrganizationId } = useParams();
    const matchWithDevsOnly = useSelector(selectMatchWithDevsOnly);
    const ignorePreDevEmails = useSelector(selectIgnorePreDevEmails);

    const onSubmit = e => {
        e.preventDefault();
        if (search === searchQuery) return;
        dispatch(setSearchQuery(search));
    };

    const onChange = e => {
        setSearch(e.target.value);
    };

    const onCancel = () => {
        setSearch('');
        dispatch(setSearchQuery(''));
    };

    const isPreDevEnterprise = [
        '65a2d025d2d5ba93f51aa7a6',
        '6513c5c74a6d10a933501593'
    ].includes(enterpriseOrganizationId);

    const creationSelections = [
        { id: null, title: 'All' },
        ...(['65a2d025d2d5ba93f51aa7a6', '6513c5c74a6d10a933501593'].includes(
            enterpriseOrganizationId
        )
            ? [{ id: 'Solo', title: 'Solo' }]
            : []),
        { id: 'Internal', title: 'Internal' },
        { id: 'Widget', title: 'Widget' }
    ];

    const handleMatchWithDevsOnlyChange = () => {
        dispatch(setMatchWithDevsOnly(!matchWithDevsOnly));
    };

    const handleIgnorePreDevEmailsChange = () => {
        dispatch(setIgnorePreDevEmails(!ignorePreDevEmails));
    };

    return (
        <div className="">
            <form onSubmit={onSubmit} className="mx-2 mt-4">
                <label htmlFor="chat-input" className="sr-only">
                    Search projects
                </label>
                <div className="relative">
                    <input
                        id="search-chats"
                        type="text"
                        className="w-full rounded-lg border border-slate-300 bg-slate-50 p-3 pr-10 text-sm text-slate-800 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-slate-700 dark:bg-slate-900 dark:text-slate-200"
                        placeholder="Search projects"
                        value={search}
                        onChange={onChange}
                        rows="1"
                        required
                    />

                    {searchQuery !== '' && (
                        <button
                            onClick={onCancel}
                            type="button"
                            className="absolute mt-1 right-[42px] rounded-full p-1"
                        >
                            <XMarkIcon className="h-6 w-6 text-gray-500" />
                        </button>
                    )}

                    <button
                        type="submit"
                        disabled={searchLoading}
                        className="absolute bottom-2 right-2.5 rounded-lg p-2 text-sm text-slate-500 hover:text-blue-700 focus:outline-none sm:text-base"
                    >
                        <span className="sr-only">Search products</span>
                        {searchLoading ? (
                            <Spinner w="5" />
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <path d="M8 9h8"></path>
                                <path d="M8 13h5"></path>
                                <path d="M11.008 19.195l-3.008 1.805v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5"></path>
                                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                <path d="M20.2 20.2l1.8 1.8"></path>
                            </svg>
                        )}
                    </button>
                </div>
            </form>

            {showFilter && (
                <div className="mt-4 flex flex-col md:flex-row gap-x-4 gap-y-4 w-full">
                    <Checkbox
                        title="Creation"
                        onClick={id => {
                            dispatch(setCreationMethodFilter(id));
                        }}
                        tabs={creationSelections}
                    />
                    <div className="flex flex-col md:flex-row gap-x-4 gap-y-4 bg-white rounded-lg shadow-sm">
                        <div className="flex items-center space-x-3">
                            <input
                                id="match-with-devs-only"
                                type="checkbox"
                                checked={matchWithDevsOnly}
                                onChange={handleMatchWithDevsOnlyChange}
                                className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition duration-150 ease-in-out"
                            />
                            <label
                                htmlFor="match-with-devs-only"
                                className="text-sm font-medium text-gray-700 cursor-pointer select-none"
                            >
                                Show dev match-seeking projects only
                            </label>
                        </div>
                        {isPreDevEnterprise && (
                            <div className="flex items-center space-x-3">
                                <input
                                    id="ignore-pre-dev-emails"
                                    type="checkbox"
                                    checked={ignorePreDevEmails}
                                    onChange={handleIgnorePreDevEmailsChange}
                                    className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition duration-150 ease-in-out"
                                />
                                <label
                                    htmlFor="ignore-pre-dev-emails"
                                    className="text-sm font-medium text-gray-700 cursor-pointer select-none"
                                >
                                    Ignore pre.dev emails
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Checkbox({ title, onClick, tabs }) {
    const [selectedOption, setSelectedOption] = useState(tabs[0].id);

    const onChange = e => {
        setSelectedOption(e);
        onClick(e);
    };

    return (
        <div className="w-fit flex flex-row gap-x-2 gap-y-4 items-center h-[40px] bg-gray-50 border border-gray-300 rounded-md px-4 py-2 text-sm font-medium">
            <div className="flex items-center justify-between">
                <h2 className="text-xs font-medium text-gray-900">{title}</h2>
            </div>

            <RadioGroup value={selectedOption} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                    Choose a {title.toLowerCase()} option
                </RadioGroup.Label>
                <div className="flex items-center space-x-2">
                    {tabs.map(option => (
                        <RadioGroup.Option
                            key={option.id}
                            value={option.id}
                            className={({ active, checked }) =>
                                classNames(
                                    'cursor-pointer focus:outline-none',
                                    active
                                        ? 'ring-2 ring-blue-600 ring-offset-2'
                                        : '',
                                    checked
                                        ? 'bg-blue-600 text-white hover:bg-blue-500'
                                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-md px-4 py-0.5 text-sm font-semibold'
                                )
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <input
                                        id={option.id}
                                        name={title.toLowerCase()}
                                        type="radio"
                                        value={option.id}
                                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 sr-only"
                                    />
                                    <label
                                        htmlFor={option.id}
                                        className={classNames(
                                            'block text-xs font-medium leading-6',
                                            checked
                                                ? 'text-white'
                                                : 'text-gray-900'
                                        )}
                                    >
                                        {option.title}
                                    </label>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
}
