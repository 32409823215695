import { useState } from 'react';
import { UserAuthForm } from '../../Auth/user-auth-form';
import Modal from '../../Components/Modal';
import { setRedirectURL } from '../../../constants';
import { useProject } from '../../../context-providers/Project';

export function LoginToContinue() {
    const [showAuthModal, setShowAuthModal] = useState(false);
    const { projectId } = useProject();

    const handleOpenAuthModal = () => {
        setRedirectURL(`/projects/${projectId}`);
        setShowAuthModal(true);
    };
    const handleCloseAuthModal = () => setShowAuthModal(false);

    return (
        <>
            <button
                onClick={handleOpenAuthModal}
                className="bg-gradient-to-r flex text-xs rounded-lg items-center flex-row gap-x-2 from-gray-900 to-black text-white font-bold py-2 px-3 rounded border border-white hover:from-black hover:to-gray-900 hover:text-gray-200 transition-all duration-300 ease-in-out"
            >
                <img src="/predev.png" className="h-4" />
                Login for Full Access
            </button>
            <Modal
                open={showAuthModal}
                onClose={handleCloseAuthModal}
                maxWidth="max-w-2xl mt-12 sm:mt-0"
                bgColor="bg-black"
            >
                <h2 className="text-2xl font-extrabold">
                    <span className="mr-2">🚀</span>
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-500">
                        View Your Project Details
                    </span>
                </h2>
                <p className="mt-2 text-gray-300 text-md">
                    Sign up for free to bring your ideas to life.
                </p>

                <UserAuthForm />
            </Modal>
        </>
    );
}
