'use client';

import { buttonVariants } from '../Components/magicui/ui/button';
import { ChevronLeft } from 'lucide-react';
import {
    emailPasswordSignUp,
    doesEmailExist,
    getAuthorisationURLWithQueryParamsAndSetState,
    thirdPartySignInAndUp,
    emailPasswordSignIn
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';

import { Input } from '../Components/magicui/ui/input';
import { cn } from '../Components/magicui/lib/utils';
import { GitHubLogoIcon } from '@radix-ui/react-icons';
import { Loader2 } from 'lucide-react';
import * as React from 'react';
import * as z from 'zod';
import { getWebsiteDomain } from '../../auth_config';
import { useEffect, useState } from 'react';
import RadialGradient from '../Components/magicui/RadialGradient';
import {
    useNavigate,
    Link,
    useSearchParams,
    useLocation
} from 'react-router-dom';
import {
    getRedirectURL,
    setRedirectURL,
    getTermsOfServiceURL
} from '../../constants';

export const userAuthSchema = z.object({
    email: z.string().email(),
    password: z.string().optional()
});

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isGitHubLoading, setIsGitHubLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isSignup, setIsSignup] = useState(true);
    const [redirectLoading, setRedirectLoading] = useState(false);

    const [params] = useSearchParams();

    useEffect(() => {
        if (params.get('redirectToPath')) {
            setRedirectURL(params.get('redirectToPath'));
        }
    }, []);

    const navigate = useNavigate();

    const checkEmail = async email => {
        try {
            let response = await doesEmailExist({
                email
            });

            if (response.doesExist) {
                alert('Email already exists. Please sign in instead');
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
            } else {
                window.alert('Oops! Something went wrong.');
            }
        }
    };

    async function handleThirdPartyCallback() {
        try {
            setRedirectLoading(true);
            const response = await thirdPartySignInAndUp();
            if (response.status === 'OK') {
                if (response.createdNewRecipeUser) {
                    // sign up successful
                } else {
                    // sign in successful
                }
                setRedirectLoading(false);
                navigate(getRedirectURL(params.get('redirectToPath')));
            } else {
                // SuperTokens requires that the third party provider
                // gives an email for the user. If that's not the case, sign up / in
                // will fail.

                // As a hack to solve this, you can override the Backend functions to create a fake email for the user.
                setRedirectLoading(false);
                window.alert(
                    'No email provided by social login. Please use another form of login'
                );
                navigate('/auth'); // redirect back to login page
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
                setRedirectLoading(false);
            } else {
                window.alert(err);
                setRedirectLoading(false);
            }
        }
    }

    // handle google redirect
    const location = useLocation();

    useEffect(() => {
        const callbackRouteGoogle = '/auth/callback/google';
        const callBackRouteGithub = '/auth/callback/github';
        const callBackRouteApple = '/auth/callback/apple';
        const callBackRouteAtlassian = '/auth/callback/atlassian';
        const callBackRouteFigma = '/auth/callback/figma';
        const callBackRouteLinear = '/auth/callback/linear';
        if (
            location.pathname === callbackRouteGoogle ||
            location.pathname === callBackRouteGithub ||
            location.pathname === callBackRouteApple ||
            location.pathname === callBackRouteAtlassian ||
            location.pathname === callBackRouteFigma ||
            location.pathname === callBackRouteLinear
        ) {
            handleThirdPartyCallback();
        }
    }, [location]); // Only re-run the effect if location changes
    // Update for Atlassian sign-in
    async function thirdPartySignInClicked(name) {
        setRedirectLoading(true);
        if (name == 'google') setIsGoogleLoading(true);
        if (name == 'github') setIsGitHubLoading(true);

        try {
            const authUrl = await getAuthorisationURLWithQueryParamsAndSetState(
                {
                    thirdPartyId: name,
                    frontendRedirectURI:
                        getWebsiteDomain() + '/auth/callback/' + name
                }
            );

            // Redirect user to the OAuth provider
            window.location.assign(authUrl);
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                window.alert(err.message);
            } else {
                console.log(err);
            }
            setRedirectLoading(false);
        }
    }

    const handleSubmitSignin = async e => {
        e.preventDefault();
        if (email.length < 1) {
            alert('Please enter your email and password to login.');
            setIsLoading(false);

            return;
        }
        if (password.length < 6) {
            alert('Your password must be at least 6 characters');
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        try {
            let response = await emailPasswordSignIn({
                formFields: [
                    {
                        id: 'email',
                        value: email
                    },
                    {
                        id: 'password',
                        value: password
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
            } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
                window.alert('Email password combination is incorrect.');
                setIsLoading(false);
            } else {
                // sign in successful. The session tokens are automatically handled by
                // the frontend SDK.
                setIsLoading(true);
                navigate(getRedirectURL(params.get('redirerctToPath')));
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
                setIsLoading(false);
            } else {
                window.alert(err);
                setIsLoading(false);
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);

        if (password.length < 6) {
            alert('Your password must be at least 6 characters');
            setIsLoading(false);
            setIsGitHubLoading(false);
            setIsGoogleLoading(false);
            return;
        }

        try {
            await checkEmail(email);
            let response = await emailPasswordSignUp({
                formFields: [
                    {
                        id: 'email',
                        value: email
                    },
                    {
                        id: 'password',
                        value: password
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
                setIsLoading(false);
                setIsGitHubLoading(false);
                setIsGoogleLoading(false);
            } else {
                // sign up successful. The session tokens are automatically handled by
                // the frontend SDK.
                setIsLoading(false);
                setIsGitHubLoading(false);
                setIsGoogleLoading(false);
                navigate('/auth/verify-email?rid=emailverification');
            }
        } catch (err) {
            setIsLoading(false);
            setIsGitHubLoading(false);
            setIsGoogleLoading(false);
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
            } else {
                window.alert(err);
            }
        }
    };

    return (
        <div className="dark bg-black">
            <div className="container flex h-full w-full p-6 flex-col items-center justify-center">
                {/* <Link
        to="/"
        className={cn(
          buttonVariants({ variant: "ghost" }),
          "absolute left-4 text-white top-4 md:left-8 md:top-8"
        )}
      >
        <>
          <ChevronLeft className="mr-2 h-4 w-4" />
          Back
        </>
      </Link> */}
                <div className="mx-auto flex w-full flex-col justify-center gap-6 sm:w-[350px]">
                    <div className="flex flex-col gap-2 text-center">
                        {/* <Icons.logo className="mx-auto h-6 w-6" /> */}
                        <img
                            className="h-10 mx-auto rounded"
                            src="/predev.png"
                            alt="logo"
                        />
                        <h1 className="text-2xl font-semibold tracking-tight text-white">
                            {isSignup ? (
                                <>
                                    Welcome to{' '}
                                    <span className="text-blue-600">
                                        {' '}
                                        pre.dev{' '}
                                    </span>
                                </>
                            ) : (
                                <>
                                    Welcome back to{' '}
                                    <span className="text-blue-600">
                                        {' '}
                                        pre.dev{' '}
                                    </span>
                                </>
                            )}
                        </h1>
                        {/* <p className="text-sm text-muted-foreground">
                            {redirectLoading
                                ? 'Loading your account...'
                                : isSignup
                                  ? 'Sign up for an account'
                                  : 'Sign in to your account'}
                        </p> */}
                    </div>

                    {!redirectLoading ? (
                        <>
                            <div
                                className={cn('grid gap-6', className)}
                                {...props}
                            >
                                <form
                                    onSubmit={
                                        isSignup
                                            ? handleSubmit
                                            : handleSubmitSignin
                                    }
                                >
                                    <div className="grid gap-4">
                                        <Input
                                            id="email"
                                            value={email}
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="name@example.com"
                                            type="email"
                                            className="text-white"
                                            autoCapitalize="none"
                                            autoComplete="email"
                                            autoCorrect="off"
                                            disabled={
                                                isLoading || isGitHubLoading
                                            }
                                        />
                                        <Input
                                            id="password"
                                            value={password}
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                            placeholder="Password"
                                            type="password"
                                            className="text-white"
                                            autoCapitalize="none"
                                            autoComplete="none"
                                            autoCorrect="off"
                                            disabled={
                                                isLoading || isGitHubLoading
                                            }
                                        />

                                        <button
                                            type="submit"
                                            className={cn(buttonVariants())}
                                            disabled={
                                                isLoading ||
                                                isGitHubLoading ||
                                                isGoogleLoading
                                            }
                                        >
                                            {isLoading && (
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            )}
                                            {isSignup
                                                ? 'Sign Up with Email'
                                                : 'Sign In with Email'}
                                        </button>
                                    </div>
                                </form>

                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <span className="w-full border-t" />
                                    </div>
                                    <div className="relative flex justify-center text-xs uppercase">
                                        <span className=" px-2 text-muted-foreground">
                                            Or continue with
                                        </span>
                                    </div>
                                </div>

                                <div className="flex gap-4">
                                    <button
                                        type="button"
                                        className={cn(
                                            buttonVariants({
                                                variant: 'outline'
                                            }),
                                            'text-white flex-1'
                                        )}
                                        onClick={() =>
                                            thirdPartySignInClicked('google')
                                        }
                                        disabled={
                                            isLoading ||
                                            isGitHubLoading ||
                                            isGoogleLoading
                                        }
                                    >
                                        {isGoogleLoading ? (
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        ) : (
                                            <svg
                                                className="w-4 h-4 mr-2"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 18 19"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        )}{' '}
                                        Google
                                    </button>

                                    <button
                                        type="button"
                                        className={cn(
                                            buttonVariants({
                                                variant: 'outline'
                                            }),
                                            'text-white flex-1'
                                        )}
                                        onClick={() =>
                                            thirdPartySignInClicked('github')
                                        }
                                        disabled={isLoading}
                                    >
                                        {isGitHubLoading ? (
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        ) : (
                                            <GitHubLogoIcon className="mr-2 h-4 w-4" />
                                        )}{' '}
                                        Github
                                    </button>
                                </div>
                            </div>

                            <p className="text-center text-sm text-gray-300">
                                <a
                                    onClick={() => setIsSignup(!isSignup)}
                                    className="cursor-pointer hover:text-brand underline underline-offset-4"
                                >
                                    {isSignup
                                        ? 'Already have an account? Sign In'
                                        : "Don't have an account? Sign Up"}
                                </a>
                            </p>
                            {!isSignup && (
                                <Link
                                    to="/auth/forgot-password"
                                    className="text-sm font-light text-blue-500 text-center cursor-pointer hover:underline dark:text-blue-400"
                                >
                                    Forgot your password?
                                </Link>
                            )}
                        </>
                    ) : (
                        <div className="w-full">
                            <Loader2 className="mx-auto text-white justify-center h-6 w-6 animate-spin" />
                        </div>
                    )}

                    {isSignup && (
                        <div className="flex items-start">
                            <div className="ml-3 text-sm">
                                <label
                                    htmlFor="terms"
                                    className="font-light text-muted-foreground"
                                >
                                    By signing up, you accept the{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                        href={getTermsOfServiceURL()}
                                    >
                                        Terms and Conditions
                                    </a>
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <RadialGradient />
        </div>
    );
}
