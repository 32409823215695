import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import {
    ArrowRightOnRectangleIcon,
    ShareIcon,
    TrashIcon
} from '@heroicons/react/24/outline';
import { deleteProject } from '../../../../redux/actions/solo/deleteProject';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../Components/Spinner';
import { SwitchDefault } from '../../WidgetManagement/Helpers/SwitchDefault';
import { useParams } from 'react-router-dom';
import { enableLeadGen } from '../../../../redux/actions/enterprise/enableLeadGen';
import { useSelector } from 'react-redux';
import { selectIsLeadGen } from '../../../../redux/reducers/generic/project';
import { useSubscription } from '@apollo/client';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const Actions = ({
    enterpriseOrganization,
    project,
    showShareLink,
    setProject,
    setOpen,
    shareLink
}) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();

    const copyToClipboard = text => e => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        toast.info('Share Link Copied!');
    };

    const [deleting, setDeleting] = useState(false);

    const onDeleteProject = async id => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            setDeleting(true);
            await dispatch(deleteProject(id));
            setDeleting(false);
        }
    };

    const onTransferClick = async id => {
        setProject(id);
        setOpen(true);
    };

    let showToggle =
        enterpriseOrganizationId ===
            process.env.REACT_APP_PREDEV_ORGANIZATION_ID &&
        project.matchWithDev;

    return (
        <td className="whitespace-nowrap px-2 py-3 text-center flex flex-col items-center justify-center font-medium gap-y-4">
            <LeadGenToggle project={project} />
            {(!showToggle || showShareLink) && (
                <div
                    className={`flex flex-row gap-x-4 transition-opacity duration-300 ${
                        !showShareLink ? 'opacity-0' : ''
                    }`}
                >
                    <button
                        onClick={copyToClipboard(shareLink(project))}
                        className="flex flex-row items-center cursor-pointer
                                    text-gray-600 text-left text-2xs hover:text-gray-900"
                    >
                        <ShareIcon className="h-6 mr-1" />
                    </button>

                    {enterpriseOrganization?.subscriptionStatus?.status ===
                        'active' && (
                        <>
                            {!deleting ? (
                                <button
                                    onClick={() => onDeleteProject(project._id)}
                                    className="flex flex-row items-center cursor-pointer
									text-red-600 text-left text-2xs hover:text-red-900"
                                >
                                    <TrashIcon className="h-6 mr-1" />
                                </button>
                            ) : (
                                <button
                                    className="flex flex-row items-center cursor-pointer
									text-red-600 text-left text-2xs hover:text-red-900"
                                >
                                    <Spinner w="5" />
                                </button>
                            )}

                            <button
                                onClick={() => onTransferClick(project)}
                                className="flex flex-row items-center cursor-pointer
									text-blue-600 text-left text-2xs hover:text-blue-900"
                            >
                                <ArrowRightOnRectangleIcon className="h-6 mr-1" />
                            </button>
                        </>
                    )}
                </div>
            )}
        </td>
    );
};

export const LeadGenToggle = ({ project, textColor = 'text-gray-700' }) => {
    const { enterpriseOrganizationId } = useParams();
    const [leadGenLoading, setLeadGenLoading] = useState(false);
    const dispatch = useDispatch();
    const { isLeadGen } = useSubscriptionPayment();

    const toggleLeadGen = async () => {
        setLeadGenLoading(true);
        await dispatch(
            enableLeadGen({
                projectId: project._id,
                value: !project.leadGenEnabled
            })
        );
        setLeadGenLoading(false);
    };
    let showToggle =
        enterpriseOrganizationId ===
            process.env.REACT_APP_PREDEV_ORGANIZATION_ID &&
        project.matchWithDev;
    return (
        !isLeadGen &&
        showToggle && (
            <div className="flex items-center justify-center gap-x-2">
                <span className={`text-sm ${textColor}`}>Lead Gen</span>
                <SwitchDefault
                    setEnabled={toggleLeadGen}
                    enabled={project.leadGenEnabled}
                    loading={leadGenLoading}
                />
            </div>
        )
    );
};
