import { useProject } from '../../../context-providers/Project';
import { BudgetSlider } from '../../CreateNewProject/BudgetSlider';
import { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import SkillLevel from '../Docs/EditorHeader/skill_level';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSow,
    selectSkillLevel,
    selectBudgetFilterActivated,
    setBudgetFilterActivated,
    selectPrices,
    setPrice,
    toggleQuoteMode,
    selectQuoteMode
} from '../../../redux/reducers/generic/sow';
import {
    parseTotalHours,
    getPrice,
    toHumanPrice
} from '../Docs/EditorHeader/PricingSection';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { updateProjectBudget } from '../../../redux/actions/solo/updateProjectBudget';
import { updateMatchWithDev } from '../../../redux/actions/solo/updateMatchWithDev';
import {
    toggleBillingModalOpen,
    toggleSubscribeModal
} from '../../../redux/reducers/generic/billing';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { toast } from 'react-hot-toast';

export const Budget = () => {
    const { project, updateProject } = useProject();
    const [budget, setBudget] = useState(project.budget || 5000);
    const { isEnterprise, isShare, isSubbed, isSolo, isHomePage } =
        useSubscriptionPayment();
    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    const dispatch = useDispatch();
    const [debouncedBudget, setDebouncedBudget] = useState(budget);
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const canEdit = project.userEmail === userEmail || isHomePage;
    const prices = useSelector(selectPrices);

    console.log({ project, userEmail, canEdit });

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedBudget(budget);
        }, 500);

        return () => clearTimeout(timer);
    }, [budget]);

    useEffect(() => {
        if (debouncedBudget !== project.budget && canEdit) {
            dispatch(
                updateProjectBudget(project._id, debouncedBudget.toString())
            );
        }
    }, [debouncedBudget, project._id, canEdit]);

    const handleBudgetChange = newBudget => {
        if (!isShare && canEdit) {
            setBudget(newBudget);
        } else if (!canEdit) {
            toast.error("You don't have permission to edit the budget.");
        }
    };

    let [matchLoading, setMatchLoading] = useState(false);
    const handleMatchWithDevChange = async newMatchWithDev => {
        if (!isShare && canEdit) {
            setMatchLoading(true);
            await dispatch(updateMatchWithDev(project._id, newMatchWithDev));
            setMatchLoading(false);
        } else if (!canEdit) {
            toast.error("You don't have permission to change this setting.");
        }
    };

    const [isMinimized, setIsMinimized] = useState(() => {
        const storedValue = window.localStorage.getItem('budgetMinimized');
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const toggleMinimize = () => {
        const newValue = !isMinimized;
        setIsMinimized(newValue);
        window.localStorage.setItem(
            'budgetMinimized',
            JSON.stringify(newValue)
        );
    };

    let sowContent = useSelector(selectSow);
    const skillLevel = useSelector(selectSkillLevel);

    const hours = sowContent ? parseTotalHours(sowContent?.milestones) : 0;
    const [price, setPriceLocal] = useState(
        getPrice(skillLevel, hours, prices)
    );

    useEffect(() => {
        const calcPrice = getPrice(skillLevel, hours, prices);
        setPriceLocal(calcPrice);
        dispatch(setPrice(calcPrice));
    }, [prices, skillLevel, hours]);

    console.log({ hours, price });

    return (
        <div className="w-full bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-lg max-w-sm my-2 mx-auto border-2 border-gray-700 py-4 px-4">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-gray-100 flex items-center gap-x-2">
                    <img src="/predev.png" alt="Budget" className="h-5" />
                    Budget
                </h2>
                <div className="flex items-center">
                    {isMinimized && architectureLoading ? (
                        <div className="flex items-center space-x-2 mr-4">
                            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                            <p className="text-xs text-gray-300">
                                Calculating...
                            </p>
                        </div>
                    ) : (
                        isMinimized &&
                        hours > 0 && (
                            <div className="flex items-center space-x-2 mr-4">
                                <span className="text-sm font-semibold text-gray-300">
                                    Est. Price: {toHumanPrice(price)}
                                </span>
                            </div>
                        )
                    )}
                    <button
                        onClick={toggleMinimize}
                        className="text-gray-100 hover:text-gray-300 transition-colors duration-200"
                    >
                        {isMinimized ? (
                            <ChevronDown size={20} />
                        ) : (
                            <ChevronUp size={20} />
                        )}
                    </button>
                </div>
            </div>
            <div
                className={`overflow-hidden transition-all duration-400 ease-in-out ${
                    isMinimized
                        ? 'max-h-0 opacity-0'
                        : 'mt-6 max-h-[1000px] opacity-100'
                }`}
            >
                {architectureLoading ? (
                    <div className="flex items-center justify-center space-x-4 pb-4">
                        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
                        <p className="text-sm text-gray-300">
                            Calculating pricing estimate...
                        </p>
                    </div>
                ) : (
                    hours > 0 && (
                        <div className="flex flex-col items-start space-y-4 text-white">
                            <div className="flex items-center justify-between w-full">
                                <SkillLevel />

                                <div className="flex items-center space-x-2">
                                    <span className="text-xl font-semibold">
                                        ~{hours}
                                    </span>
                                    <span className="text-md"> Hours</span>
                                </div>
                            </div>
                        </div>
                    )
                )}

                <BudgetSlider
                    budget={budget}
                    estimateData={
                        !architectureLoading && hours > 0 ? price / 100 : null
                    }
                    setBudget={handleBudgetChange}
                    textColor="text-white"
                    disabled={!canEdit}
                />

                <div className="flex justify-between mt-4 ml-0.5">
                    <div className="w-1/2 pr-2">
                        <FilterByBudget />
                    </div>
                    {isEnterprise && (
                        <div className="w-1/2 pl-2">
                            <QuoteMode />
                        </div>
                    )}
                    {!isEnterprise && (
                        <>
                            <div className="w-1/2 pl-2">
                                <MatchWithDev
                                    isShare={isShare}
                                    matchWithDev={project.matchWithDev}
                                    setMatchWithDev={handleMatchWithDevChange}
                                    matchLoading={matchLoading}
                                    isSubbed={isSubbed}
                                    canEdit={canEdit}
                                />
                            </div>
                        </>
                    )}
                </div>
                {!isEnterprise && project.matchWithDev && (
                    <div className="mt-4">
                        <a
                            href="https://calendly.com/pre_dev/free-expert-consultation"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700"
                        >
                            Free Dev Consultation
                        </a>
                    </div>
                )}
                {isSolo && !isSubbed && project.matchWithDev && (
                    <div className="mt-4 bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg p-3 shadow-lg">
                        <p className="text-white text-sm font-semibold">
                            Jump to the Front!
                        </p>
                        <p className="text-xs text-gray-100 mt-1">
                            Get priority access to the front of the queue
                        </p>
                        <button
                            onClick={() => {
                                dispatch(toggleSubscribeModal());
                            }}
                            className="inline-block mt-2 px-4 py-2 bg-white text-indigo-600 text-xs font-medium rounded-full hover:bg-gray-100 transition duration-300"
                        >
                            Upgrade to Solo Plus
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const MatchWithDev = ({
    isSubbed,
    isShare,
    matchWithDev,
    setMatchWithDev,
    matchLoading,
    canEdit
}) => {
    const handleChange = e => {
        if (canEdit) {
            setMatchWithDev(e.target.checked);
        } else {
            toast.error("You don't have permission to change this setting.");
        }
    };

    return (
        <div className="flex flex-col items-start space-y-2">
            <div className="flex items-center mb-2">
                <input
                    type="checkbox"
                    id="matchWithDev"
                    checked={matchWithDev}
                    onChange={handleChange}
                    className={`mr-2 ${
                        matchLoading || !canEdit
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer'
                    }`}
                    disabled={matchLoading || isShare || !canEdit}
                />
                <label
                    htmlFor="matchWithDev"
                    className={`text-xs text-gray-200 ${
                        matchLoading || !canEdit
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                    }`}
                >
                    Match me with a dev
                </label>
                {matchLoading && (
                    <div className="ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                )}
            </div>
            <p className="text-xs text-gray-300">
                Allow dev agencies to email me about this project
            </p>
        </div>
    );
};

const FilterByBudget = () => {
    const dispatch = useDispatch();
    const budgetFilterActivated = useSelector(selectBudgetFilterActivated);

    const handleFilterChange = e => {
        dispatch(setBudgetFilterActivated(e.target.checked));
    };

    return (
        <div className="flex flex-col items-start space-y-2">
            <div className="flex items-center mb-2">
                <input
                    type="checkbox"
                    id="filterByBudget"
                    checked={budgetFilterActivated}
                    onChange={handleFilterChange}
                    className="cursor-pointer"
                />
                <label
                    htmlFor="filterByBudget"
                    className="text-xs text-gray-200 ml-2 cursor-pointer"
                >
                    Filter by Budget
                </label>
            </div>
            <p className="text-xs text-gray-300">
                Only show high priority tasks within budget
            </p>
        </div>
    );
};

const QuoteMode = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const quoteMode = useSelector(selectQuoteMode);
    const handleFilterChange = e => {
        setChecked(e.target.checked);
        dispatch(toggleQuoteMode(e.target.checked));
    };

    useEffect(() => {
        setChecked(quoteMode);
    }, [quoteMode]);

    return (
        <div className="flex flex-col items-start space-y-2">
            <div className="flex items-center mb-2">
                <input
                    type="checkbox"
                    id="filterByBudget"
                    checked={checked}
                    onChange={handleFilterChange}
                    className="cursor-pointer"
                />
                <label
                    htmlFor="filterByBudget"
                    className="text-xs text-gray-200 ml-2 cursor-pointer"
                >
                    Quote Mode
                </label>
            </div>
            <p className="text-xs text-gray-300">
                Hides sub-tasks and includes pricing.
            </p>
        </div>
    );
};
