import {
    createSlice,
    createListenerMiddleware,
    isAnyOf
} from '@reduxjs/toolkit';
import { updateHourEstimates } from './update_hour_estimates';

const initialState = {
    data: {
        type: 'mvp'
    },
    loadingSoW: {
        mvp: null,
        custom: null
    },
    hideHours: true,
    editMode: false,
    skillLevel: localStorage.getItem('skillLevel') || 'junior',
    loadingMVP: null,
    loadingCustom: null,
    budget: 0,
    budgetFilterActivated:
        localStorage.getItem('budgetFilterActivated') === 'true' || false,
    modal: false,
    prices: null,
    price: null,
    quoteMode: false
};

export const sowSlice = createSlice({
    name: 'sow',
    initialState,
    reducers: {
        setPrices: (state, action) => {
            state.prices = action.payload;
        },
        setPrice: (state, action) => {
            state.price = action.payload;
        },
        setSow: (state, action) => {
            let sow = JSON.parse(JSON.stringify(action.payload));
            if (sow?.milestones) {
                sow.og_milestones = JSON.parse(JSON.stringify(sow.milestones));
                sow.milestones = updateHourEstimates(
                    JSON.parse(JSON.stringify(sow.og_milestones)),
                    state.skillLevel,
                    state.budget,
                    state.budgetFilterActivated
                );
            }
            sow?.milestones?.forEach(milestone => {
                milestone.stories.forEach(story => {
                    if (!story.title) story.title = story.description;
                });
            });
            state.data = sow;
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
        },
        setSkillLevel: (state, action) => {
            state.skillLevel = action.payload;
            localStorage.setItem('skillLevel', action.payload);
            if (state.data?.milestones) {
                state.data.milestones = updateHourEstimates(
                    JSON.parse(JSON.stringify(state.data.og_milestones)),
                    action.payload,
                    state.budget,
                    state.budgetFilterActivated
                );
            }
        },
        setHideHours: (state, action) => {
            state.hideHours = action.payload;
        },
        waitingForSowMVP: (state, action) => {
            state.loadingMVP = action.payload;
        },
        waitingForSowCustom: (state, action) => {
            state.loadingCustom = action.payload;
        },
        setShowSowModal: (state, action) => {
            state.modal = action.payload;
        },
        updateSowWithBudget: (state, action) => {
            const { budget } = action.payload;
            state.budget = budget;
            if (state.data?.milestones) {
                state.data.milestones = updateHourEstimates(
                    JSON.parse(JSON.stringify(state.data.og_milestones)),
                    state.skillLevel,
                    budget,
                    state.budgetFilterActivated
                );
            }
        },
        toggleQuoteMode: (state, action) => {
            state.quoteMode = action.payload;
        },
        setBudgetFilterActivated: (state, action) => {
            state.budgetFilterActivated = action.payload;
            localStorage.setItem(
                'budgetFilterActivated',
                action.payload.toString()
            );
            if (state.data?.milestones && state.budget) {
                state.data.milestones = updateHourEstimates(
                    JSON.parse(JSON.stringify(state.data.og_milestones)),
                    state.skillLevel,
                    state.budget,
                    action.payload
                );
            }
        }
    }
});

export const {
    setEditMode,
    setHideHours,
    setSow,
    setShowSowModal,
    waitingForSowMVP,
    waitingForSowCustom,
    setSkillLevel,
    updateSowWithBudget,
    setBudgetFilterActivated,
    setPrices,
    toggleQuoteMode,
    setPrice
} = sowSlice.actions;

export const selectSow = state => state.sow.data;
export const selectShowSowModal = state => state.sow.modal;
export const selectLoadingSowMVP = state => state.sow.loadingMVP;
export const selectLoadingSowCustom = state => state.sow.loadingCustom;
export const selectSkillLevel = state => state.sow.skillLevel;
export const selectHideHours = state => state.sow.hideHours;
export const selectEditMode = state => state.sow.editMode;
export const selectPrices = state => state.sow.prices;
export const selectBudgetFilterActivated = state =>
    state.sow.budgetFilterActivated;

export const selectQuoteMode = state => state.sow.quoteMode;
export const selectPrice = state => state.sow.price;

export default sowSlice.reducer;

// Create the listener middleware
export const sowListenerMiddleware = createListenerMiddleware();

// Add a listener that responds to project budget changes
sowListenerMiddleware.startListening({
    matcher: isAnyOf(
        action => action.type === 'project/setProjectBudget',
        action => action.type === 'project/fetchProjectSuccess'
    ),
    effect: (action, listenerApi) => {
        const state = listenerApi.getState();
        const budget =
            action.type === 'project/setProjectBudget'
                ? action.payload
                : state.project.data.budget;
        listenerApi.dispatch(updateSowWithBudget({ budget }));
    }
});
